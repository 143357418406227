import { authHeader, handleResponse } from "../helpers";

export const homeService = {
  create,
  index,
  _delete,
  update,
};

function create(user) {
  let formBody = [];
  for (let property in user) {
    let encodedKey = encodeURIComponent(property);
    let encodedValue = encodeURIComponent(user[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: formBody,
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}api/v1/home`,
    requestOptions
  ).then(handleResponse);
}

function index(request) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let url = new URL(`${process.env.REACT_APP_API_URL}api/v1/home`);
  return fetch(url, requestOptions).then(handleResponse);
}

function _delete(id) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}api/v1/home/${id}`,
    requestOptions
  ).then(handleResponse);
}

function update(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}api/v1/home-update`,
    requestOptions
  ).then(handleResponse);
}
