import { authHeader,handleResponse } from '../helpers';

export const chatService = {
    index,
    add,
    getById,
    _delete,
    getUnreadMsg,
    createConversation
}

function createConversation (request) {
    let formBody = [];
    for (let property in request) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(request[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader() , 'Content-Type': 'application/x-www-form-urlencoded' },
        body: formBody
    };
    let url = new URL(`${process.env.REACT_APP_API_URL}api/v1/conversations/createConversationByAdmin`)
    return fetch(url, requestOptions).then(handleResponse);
}

function index(request) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = new URL(`${process.env.REACT_APP_API_URL}api/v1/conversations`)
    return fetch(url, requestOptions).then(handleResponse);
}

function getUnreadMsg() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = new URL(`${process.env.REACT_APP_API_URL}api/v1/conversations/getUnreadMessages`)
    return fetch(url, requestOptions).then(handleResponse);
}

function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    let url = new URL(`${process.env.REACT_APP_API_URL}api/v1/conversations/${id}`)
    return fetch(url, requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = new URL(`${process.env.REACT_APP_API_URL}api/v1/conversations/${id}`)
    return fetch(url, requestOptions).then(handleResponse);
}

function add(request) {
    let formBody = [];
    for (let property in request) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(request[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader() , 'Content-Type': 'application/x-www-form-urlencoded' },
        body: formBody
    };
    let url = new URL(`${process.env.REACT_APP_API_URL}api/v1/conversations/sendMessageByAdmin`)
    return fetch(url, requestOptions).then(handleResponse);
}


  