import { authHeader } from '../helpers';
export const uvRepService = {
    getList,
    add,
    getById,
    update,
    _delete,
    shoutoutList,
    getShoutById,
    storeOrderList,
    liveOneOnOneList,
    attachBank,
    retreiveBank,
    transactionListing
};

function getList(params) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = new URL(`${process.env.REACT_APP_API_URL}api/v1/area_of_concentrations`)
    return fetch(url, requestOptions).then(handleResponse);
}

function shoutoutList(params) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = new URL(`${process.env.REACT_APP_API_URL}api/v1/requestShoutOut/list`)
    url.search = new URLSearchParams({
        offset: params.offset,
        limit: params.limit,
        userId: params.userId,
        performerId: params.performerId
    })
    return fetch(url, requestOptions).then(handleResponse);
}

function getShoutById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/v1/requestShoutOut/${id}`, requestOptions).then(handleResponse);
}

function storeOrderList(params) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = new URL(`${process.env.REACT_APP_API_URL}api/v1/admin/store-orders`)
    url.search = new URLSearchParams({
        offset: params.offset,
        limit: params.limit
    })
    return fetch(url, requestOptions).then(handleResponse);
}

function liveOneOnOneList(params) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = new URL(`${process.env.REACT_APP_API_URL}api/v1/liveChatRequests`)
    url.search = new URLSearchParams({
        offset: params.offset,
        limit: params.limit
    })
    return fetch(url, requestOptions).then(handleResponse);
}

function add(user) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: user
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/v1/users`, requestOptions)
        .then(handleResponse);
}

function attachBank(user) {
    let formBody = [];
    for (let property in user) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(user[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader() , 'Content-Type': 'application/x-www-form-urlencoded' },
        body: formBody
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/v1/users/attachBankAccount`, requestOptions)
        .then(handleResponse);
}

function retreiveBank(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/v1/users/${id}/retrieveAccountInformation`, requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/v1/users/${id}`, requestOptions).then(handleResponse);
}

function update(user, id) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: user
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/v1/users/${id}`, requestOptions)
        .then(handleResponse);
}

function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/v1/users/${id}`, requestOptions)
        .then(handleResponse);
}


function handleResponse(response) {
    return response.json().then(text => {
        const data = text;
        if (!response.ok) {
            /* if (response.status === 401) {
                // auto logout if 401 response returned from api
                //logout();
                //location.reload(true);
            } */

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

function transactionListing(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    let url = new URL(`${process.env.REACT_APP_API_URL}api/v1/transactionListing`)
    url.search = new URLSearchParams(data)
    return fetch(url, requestOptions).then(handleResponse);
}