import { uvNewsConstants } from '../constants';

const initialState = {};
export function uvNews(state = initialState, action) {
  switch (action.type) {
    case uvNewsConstants.GET_SUCCESS:
      const data = action.result && action.result.data && action.result.data.map(function(res, index) {
            /* res['type'] = res.hasOwnProperty('gallery') ? 'Gallery' : (
              res.hasOwnProperty('performer') ? 'Performer' : 'Product'); */
            res['name'] = res.type === 'gallery' ? res.gallery.name : (
              res.type ===  'celebencer' ? (res.performer.firstName != undefined ? res.performer.firstName +' '+ res.performer.lastName: res.performer.name) : res.product.name);
              return res;
          });
      return {
        list : data, //data.filter(item => item.status != 'Inactive')
      };
    case uvNewsConstants.UPDATE_REQUEST:
    return {
      ...state,
      delete: false,
    }  
    case uvNewsConstants.UPDATE_SUCCESS:
      let index_val = state.list.findIndex(item => item._id === action.result.data._id);
      return {
        ...state,
        delete: true,
        list: state.list.filter((item) => item._id != action.result.data._id),
        index: state.list[index_val]
      };
    default:
      return state
  }
}