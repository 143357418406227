import { authHeader,handleResponse } from '../helpers';

export const meetAndGreetService = {
    list,
    detail, 
    getOrderList, 
    getOrderDetail, 
    refund,
    refundAll,
    manualPayout
}

function list(params) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = new URL(`${process.env.REACT_APP_API_URL}api/v1/meetAndGreet/list`)
    url.search = new URLSearchParams({
        offset: params.offset,
        limit: params.limit,
        keyword: params.keyword || ''
    })
    return fetch(url, requestOptions).then(handleResponse);
}

function detail(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = new URL(`${process.env.REACT_APP_API_URL}api/v1/meetAndGreet/${id}`)
   
    return fetch(url, requestOptions).then(handleResponse);
}

function getOrderList(params) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = new URL(`${process.env.REACT_APP_API_URL}api/v1/meetAndGreet/orders`);
    url.searchParams.set('eventId', params?.eventId);  
    url.searchParams.set('limit', params?.limit);
    url.searchParams.set('offset', params?.offset);
    url.searchParams.set('keyword', params?.keyword || '');
   
    return fetch(url, requestOptions)
        .then(handleResponse);
}

function getOrderDetail(params) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = new URL(`${process.env.REACT_APP_API_URL}api/v1/meetAndGreet/order`);
    url.searchParams.set('orderId', params?.orderId);  
   
    return fetch(url, requestOptions)
        .then(handleResponse);
}

function refund(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/v1/meetAndGreet/refundOrderPayment/${id}`, requestOptions)
        .then(handleResponse);
}

function refundAll(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/v1/meetAndGreet/refundMeetGreetEventPayments/${id}`, requestOptions)
        .then(handleResponse);
}

function manualPayout(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/v1/meetAndGreet/completePayment/${id}`, requestOptions)
        .then(handleResponse);
}