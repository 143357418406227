import { authHeader, handleResponse } from "../helpers";

export const personalAppearnceService = {
  index,
  show,
  payout,
  refund
};

function index(params) {
  let paramOpt = {};
  for (let key in params) {
    if (params[key] !== "") {
      paramOpt[key] = params[key];
    }
  }
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let url = new URL(
    `${process.env.REACT_APP_API_URL}api/v1/personal-appearances`
  );
  url.search = new URLSearchParams(paramOpt);
  return fetch(url, requestOptions).then(handleResponse);
}

function show(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}api/v1/personal-appearances/${id}`,
    requestOptions
  ).then(handleResponse);
}

function payout(id,data) {
  let formBody = [];
  for (let property in data) {
    let encodedKey = encodeURIComponent(property);
    let encodedValue = encodeURIComponent(data[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  const requestOptions = {
      method: 'PUT',
      headers: { ...authHeader() , 'Content-Type': 'application/x-www-form-urlencoded' },
      body: formBody
  };
  return fetch(`${process.env.REACT_APP_API_URL}api/v1/personal-appearance/event-completed/${id}`, requestOptions)
      .then(handleResponse);

}

function refund(id,data) {
  let formBody = [];
  for (let property in data) {
    let encodedKey = encodeURIComponent(property);
    let encodedValue = encodeURIComponent(data[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  const requestOptions = {
      method: 'PUT',
      headers: { ...authHeader() , 'Content-Type': 'application/x-www-form-urlencoded' },
      body: formBody
  }
  return fetch(`${process.env.REACT_APP_API_URL}api/v1/personal-appearance/merchantRefund/${id}`, requestOptions)
  .then(handleResponse);

}
