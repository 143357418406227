import { settingConstants, uvRepConstants } from '../constants';
import { messageConstants, uvNewsConstants,uvStoreConstants } from '../constants';
import { uvRepService, uvNewsService, uvStoreService } from '../services';
import { alertActions } from './';

export const uvStoreActions = {
    index, 
    create,
    _delete,
    attachBank,
    updateAdminStatus
};

function index(request) {
    return dispatch => {
        return new Promise((resolve, reject) => {
        uvStoreService.index(request)
            .then(
                data => {
                    dispatch(success(data));
                    resolve(data)
                },
                error => {
                    reject()
                }
            );
        });
    };

    function success(result) { return { type: uvStoreConstants.GET_STORE_SUCCESS, result } }
}

function create(user) {
    return dispatch => {
        return new Promise((resolve, reject) => {
        dispatch(request(user));
        uvNewsService.create(user)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success(user.message[0]));
                    resolve(user)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    reject()
                }
            );
        });
    };

    function request(user) { return { type: uvNewsConstants.ADD_REQUEST, user } }
    function success(user) { return { type: uvNewsConstants.ADD_SUCCESS, user } }
    function failure(error) { return { type: uvNewsConstants.ADD_FAILURE, error } }

}

function updateAdminStatus(id, status) {
    return dispatch => {
        return new Promise((resolve, reject) => {
        dispatch(request(status));
        uvStoreService.updateAdminStatus(id, status)
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(alertActions.success(data?.message[0]));
                    resolve(data)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    reject()
                }
            );
        });
    };

    function request(data) { return { type: uvStoreConstants.CHANGE_ADMIN_STATUS_REQUEST, data } }
    function success(data) { return { type: uvStoreConstants.CHANGE_ADMIN_STATUS_SUCCESS, data } }
    function failure(error) { return { type: uvStoreConstants.CHANGE_ADMIN_STATUS_FAILURE, error } }
}

function update(user, id) {
    return dispatch => {
        return new Promise((resolve, reject) => {
        dispatch(request(user));
        uvRepService.update(user, id)
            .then(
                data => {
                    dispatch(success(data, id));
                    dispatch(alertActions.success(messageConstants.UVREP_UPDATE_SUCCESS));
                    resolve(data)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    reject()
                }
            );
        });
    };

    function request(user) { return { type: uvRepConstants.UPDATE_REQUEST, user } }
    function success(user) { return { type: uvRepConstants.UPDATE_SUCCESS, user } }
    function failure(error) { return { type: uvRepConstants.UPDATE_FAILURE, error } }

}

function _delete(id) {
    return dispatch => {
        dispatch(request(id));
        uvRepService._delete(id)
            .then(
                response => {
                    dispatch(success(id));
                    dispatch(alertActions.success(messageConstants.UVREP_UPDATE_FAILURE));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(id) { return { type: uvRepConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: uvRepConstants.DELETE_SUCCESS, id } }
    function failure(error) { return { type: uvRepConstants.DELETE_FAILURE, error } }

}

function attachBank(data) {
    return dispatch => {
        dispatch(request(data));
        return new Promise((resolve, reject) => {
        uvRepService.attachBank(data)
            .then(
                response => {
                    dispatch(success(data));
                    dispatch(alertActions.success(response.message && response.message[0]));
                    resolve(response)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    reject()
                }
            );
        });
    };

    function request(id) { return { type: uvRepConstants.UPDATE_REQUEST, id } }
    function success(id) { return { type: uvRepConstants.UPDATE_SUCCESS, id } }
    function failure(error) { return { type: uvRepConstants.UPDATE_FAILURE, error } }

}
