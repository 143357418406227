export const meetAndGreetConstants = {
    
    GET_MEET_AND_GREET_LIST_SUCCESS : "GET_MEET_AND_GREET_LIST_SUCCESS",
    GET_MEET_AND_GREET_EVENT_DETAIL_SUCCESS :  "GET_MEET_AND_GREET_EVENT_DETAIL_SUCCESS",

    GET_MEET_AND_GREET_ORDER_LIST_SUCCESS : "GET_MEET_AND_GREET_ORDER_LIST_SUCCESS",
    GET_MEET_AND_GREET_ORDER_DETAIL_SUCCESS :  "GET_MEET_AND_GREET_ORDER_DETAIL_SUCCESS",

    UPDATE_MEET_AND_GREET_EVENT_SUCCESS : "UPDATE_MEET_AND_GREET_EVENT_SUCCESS",
    UPDATE_MEET_AND_GREET_ORDER_SUCCESS : "UPDATE_MEET_AND_GREET_ORDER_SUCCESS",

    MEET_AND_GREET_EVENT_STATUS_OBJECT: {
      "active": {
        label: "Active",
        value: "active"
      },
      "completed": {
        label: "Completed",
        value: "completed"
      },
      "cancelled": {
        label: "Cancelled",
        value: "cancelled"
      },
      "payment_completed": {
        label: "Payment Completed",
        value: "payment_completed"
      }
    },
  
    MEET_AND_GREET_ORDER_STATUS_OBJECT: {
      "pending": {
        label: "Pending",
        value: "pending"
      },
      "paid": {
        label: "Paid",
        value: "paid"
      },
      "refunded": {
        label: "Refunded",
        value: "refunded"
      }
    },
  };		
		