import { userConstants, messageConstants } from '../constants';

//let user = JSON.parse(localStorage.getItem('user'));
//const initialState = user ? { loggedIn: false, user,token:false } : {};
const initialState = { 
	loggedIn: false, 
	token: false,
	bankAccounts: [],
    defaultButtonPress:false,
    addBankAccountComponent: false,
    otherBankInfo: null,
    bankErrors : [],
    updateBankAccountComponent : false,
    submitting : false 
};
export function user(state = initialState, action) {
  	switch (action.type) {
		
		case userConstants.UPDATE_REQUEST:
			return {
				submitting: true,
				submitted: false,
				user: action.user,
			};
		
		case userConstants.UPDATE_SUCCESS:
			return {
				submitted: true,
				submitting: false,
				user: action.user
			};
		
		case userConstants.UPDATE_FAILURE:
			return {
				...state,
				submitting: false,

			};
		
		case userConstants.DELETE_REQUEST:
			return {
				submitting: true,
				user: action.user,
			};
		case userConstants.DELETE_SUCCESS:
			return {
				user_deleted: true,
				user: action.user
			};
		case userConstants.DELETE_FAILURE:
			return {
				...state
			};
		case userConstants.BANK_ACCOUNT_LIST_REQUEST:
			return {
				...state,
				loading: true,
			}
		case userConstants.BANK_ACCOUNT_LIST_SUCCESS:
				let bankErrorType
				if (action?.payload?.account?.requirements?.errors?.length) {
					bankErrorType = action?.payload?.account?.requirements?.errors
				} else if (action?.payload?.account?.requirements?.errors?.length == 0 && action?.payload?.account?.requirements?.currently_due?.length > 0) {
					bankErrorType = [{ code: 'custom_error', reason: messageConstants.CUSTOM_ERROR_MESSAGE_OF_STRIPE_ACCOUNT_VARIFICATION }]
				} else {
					bankErrorType = []
				}
			return {
				...state,
				loading:false,
				bankAccounts:action?.payload?.account?.external_accounts?.data ?? [],
				bankErrors : bankErrorType,
				otherBankInfo : action?.payload?.account ?? null
			}
		case userConstants.BANK_ACCOUNT_LIST_FAILURE:
			return {
				...state,
				loading:false,
				error:action.error
			}
		case userConstants.BANK_ACCOUNT_SET_DEFAULT_REQUEST:
            return {
                ...state,
                defaultButtonPress:true
            }
        case userConstants.BANK_ACCOUNT_SET_DEFAULT_SUCCESS:
            return {
                ...state,
                defaultButtonPress:false,
                bankAccounts: state?.bankAccounts?.map((account) => {
                    if(account.id == action?.payload?.account?.id)
                        return action.payload.account 
                    else 
                        return { ...account,default_for_currency:false}
                })
            }
        case userConstants.BANK_ACCOUNT_SET_DEFAULT_FAILURE:
            return {
                ...state,
                defaultButtonPress:false,
                error: action.error
            }
        case userConstants.BANK_ACCOUNT_DELETE_REQUEST:
            return {
                ...state,
                accountDeleteRequest:true
            }
        case userConstants.BANK_ACCOUNT_DELETE_SUCCESS:
            return {
                ...state,
                accountDeleteRequest:false,
                bankAccounts: state?.bankAccounts?.filter((account) => account?.id != action?.payload?.data?.id)
            }
        case userConstants.BANK_ACCOUNT_DELETE_FAILURE:
            return {
                ...state,
                accountDeleteRequest:false,
                error: action.error
            }
		case userConstants.LOGOUT: 
			return {};
		default:
			return state
	}
}