import { categoryConstants } from '../constants';
import {categoryService} from '../services';
import { alertActions } from './';

export const categoryActions = {
    index,
    show,
    update,
    _delete,
    store,
}

function index() {
    return dispatch => {
        return new Promise((resolve, reject) => {
            categoryService.index().then(
                data => {
                    dispatch(success(data));
                    resolve(data)
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    reject()
                }
            );
        });
    };

    function success(data) { return { type: categoryConstants.GET_CATEGORIES_SUCCESS, payload:data } }
}

function show(id) {
    return categoryService.show(id);
}

function update(request, id) {
    
    return dispatch => {
        return new Promise((resolve, reject) => {
        categoryService.update(request,id)
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(alertActions.success(data.message[0]));
                    resolve(data)
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    reject()
                }
            );
        });
    };

    function success(data) { return { type: categoryConstants.UPDATE_CATEGORY_SUCCESS, payload:data.item } }
}

function _delete(id) {
    
    return dispatch => {
        return new Promise((resolve, reject) => {
        categoryService._delete(id)
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(alertActions.success(data.message[0]));
                    resolve(data)
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    reject()
                }
            );
        });
    };
    function success(data) { return { type: categoryConstants.REMOVE_CATEGORY_SUCCESS, payload:data } }
}

function store(request) {
    return dispatch => {
        return new Promise((resolve, reject) => {
        categoryService.store(request)
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(alertActions.success(data.message[0]));
                    resolve(data)
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    reject()
                }
            );
        });
    };
    function success(data) { return { type: categoryConstants.STORE_CATEGORY_SUCCESS, payload:data.item } }
}