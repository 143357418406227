import { authHeader, handleResponse } from '../helpers';

export const liveGroupChatService = {
    show,
}

function show(id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    let url = new URL(`${process.env.REACT_APP_API_URL}api/v1/stream/${id}/details`);
    return fetch(url, requestOptions).then(handleResponse);
}
