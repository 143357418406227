import { messageConstants, uvNewsConstants,uvStoreConstants } from '../constants';
import { marketPlaceService } from '../services';
import { alertActions } from './';

export const marketPlaceActions = {
    marketPlaceList
}


function marketPlaceList(params) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            marketPlaceService.index(params).then(data => {
                dispatch(success(data));
                resolve(data)
            },
            error => {
                reject()
            });
        });
    };

    function success(result) { return { type: uvStoreConstants.GET_MARKETPLACE_LIST_SUCCESS, result } }
}