import { chatConstants } from "../constants";

const initialState = {
  list: [],
  messages: [],
  scrolltoBottom: false,
  newMessage: false,
  deleted: false,
};

export function chat(state = initialState, action) {
  switch (action.type) {
    case chatConstants.NEW_CHAT:
      return {
        ...state,
        list: [],
        messages: [],
        deleted: false,
        newMessage: true,
        isActiveUser: "",
      };

    case chatConstants.GET_CHAT_SUCCESS:
      const items = action.payload.conversations.map(function (item) {
        item.userType = item.userType;
        item.id = item._id;
        item.updatedAt = item.updatedAt;
        item.firstName =
          item.performerId != undefined
            ? item.performerId
              ? item.performerId.firstName
                ? item.performerId.firstName
                : item.performerId.name
              : item.userId.firstName
              ? item.userId.firstName
              : item.userId.name
            : item.userId != undefined
            ? item.userId.firstName != undefined
              ? item.userId.firstName
              : "Name not Found"
            : "Name not Found";
        item.lastContent = item.lastContent;
        item.imageThumbPath = item.performerId
          ? item.performerId.imageThumbPath
            ? process.env.REACT_APP_MEDIA_URL + item.performerId.imageThumbPath
            : process.env.REACT_APP_USER_IMAGE
          : item.userId
          ? item.userId.imageThumbPath
            ? process.env.REACT_APP_MEDIA_URL + item.userId.imageThumbPath
            : process.env.REACT_APP_USER_IMAGE
          : process.env.REACT_APP_USER_IMAGE;
        return item;
      });
      return {
        ...state,
        list: items,
        scrolltoBottom: false,
      };

    case chatConstants.GET_MESSAGE_SUCCESS:
      const data = action.payload.messages
        .map(function (item) {
          item.senderId = item.senderId;
          item.updatedAt = item.updatedAt;
          item.content = item.content;
          item.conversationId = item.conversationId;
          return item;
        })
        .reverse();
      return {
        ...state,
        messages: data,
        newMessage: true,
      };

    case chatConstants.DELETE_REQUEST:
      return {
        ...state,
        deleted: false,
      };

    case chatConstants.ACTIVE_USER:
      return {
        ...state,
        isActiveUser: action && action.activeUserId,
      };

    case chatConstants.DELETE_MESSAGE:
      return {
        ...state,
        list: state.list.filter((data) => data._id !== action.id),
        messages: [],
        deleted: true,
      };

    case chatConstants.GET_ADD_MESSAGE_SUCCESS:
      const newData = {
        senderId: action.item && action.item.data && action.item.data.senderId,
        updatedAt:
          action.item && action.item.data && action.item.data.updatedAt,
        content: action.item && action.item.data && action.item.data.content,
        recipientId:
          action.item && action.item.data && action.item.data.recipientId._id,
        conversationId:
          action.item && action.item.data && action.item.data.conversationId,
      };

      let index = state.messages.findIndex(
        (item) => item.conversationId === action.item.conversationId
      );
      if ((index > 0 || index === -1) && action.conversationId != "") {
        return {
          ...state,
          messages: state.messages.concat(newData),
          recipientId: index,
          newMessage: false,
        };
      } else {
        return {
          ...state,
          messages: [].concat(newData),
          newMessage: true,
        };
      }

    case chatConstants.GET_ADD_NEW_MESSAGE:
      let chkValue = state.messages.findIndex(
        (item) => item._id === action.item._id
      );
      const newChat = {
        _id: action.item && action.item && action.item._id,
        senderId: action.item && action.item && action.item.senderId,
        updatedAt: action.item && action.item && action.item.updatedAt,
        content: action.item && action.item && action.item.content,
        recipientId: action.item && action.item && action.item.recipientId,
        conversationId:
          action.item && action.item && action.item.conversationId,
      };

      let index_val = state.messages.findIndex(
        (item) => item.conversationId === action.item.conversationId
      );

      if (chkValue === -1 && (index_val !== -1 || index_val >= 0)) {
        return {
          ...state,
          messages: state.messages.concat(newChat),
          newMessage: action.item,
        };
      } else if (state.newMessage === true) {
        return {
          ...state,
          messages: state.messages.concat(newChat),
          newMessage: false,
        };
      } else {
        return {
          ...state,
        };
      }

    default:
      return state;
  }
}
