export const messageConstants = {
  LOGIN_SUCCESS: "Login Successfully",
  USER_UPDATE_SUCCESS: "Data updated successfully.",
  USER_DELETE_SUCCESS: "Delete user successfully.",
  UVREP_ADD_SUCCESS: "UV Rep added successfully.",
  UVREP_UPDATE_SUCCESS: "UV Rep updated successfully.",
  UVREP_UPDATE_FAILURE: "UV Rep deleted successfully.",
  GALLERY_IMG_DELETE: "Deleted successfully",
  BANK_ACCOUNT_ERROR : "Your bank account is under varification. Please wait to varify.",
  PRODUCT_PROMOTION_PACKAGE_UPDATED : "Product promotion package updated successfully.",
  DETAILS_UPDATED : "Details Updated Successfully!",
  PASSWORD_SENT_TO_EMAIL : "New password sent to your email account.",
  PASSWORD_SENT_TO_EMAIL_ERROR : "We got issue while reset your password. Please connect with support team.",
  CUSTOM_ERROR_MESSAGE_OF_STRIPE_ACCOUNT_VARIFICATION: 'Your KYC details are not appropriate, please update it again.'
};
