import { commissionConstants } from '../constants';
import {commissionService} from '../services';
import { alertActions } from './';

export const commissionActions = {
    getList,
    show,
    update,
}

function getList() {
    
    return dispatch => {
        return new Promise((resolve, reject) => {
        commissionService.getList()
            .then(
                data => {
                    dispatch(success(data));
                    resolve(data)
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    reject()
                }
            );
        });
    };

    function success(data) { return { type: commissionConstants.GET_COMMISSION_SUCCESS, payload:data } }
}

function show(id) {
    
    return dispatch => {
        return new Promise((resolve, reject) => {
        commissionService.show(id)
            .then(
                data => {
                    dispatch(success(data));
                    resolve(data)
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    reject()
                }
            );
        });
    };

    function success(data) { return { type: commissionConstants.SHOW_COMMISSION_SUCCESS, data } }
}

function update(request, id) {
    
    return dispatch => {
        return new Promise((resolve, reject) => {
        commissionService.update(request,id)
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(alertActions.success(data.message[0]));
                    resolve(data)
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    reject()
                }
            );
        });
    };

    function success(data) { return { type: commissionConstants.UPDATE_COMMISSION_SUCCESS, payload:data } }
}