import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import Toast from './reusable/Toast';
import { useSelector } from 'react-redux';
import './scss/style.scss';
import { PrivateRoute } from './containers';
import WebSocketProvider from './WebSocket';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const ForgotPassword = React.lazy(() => import('./views/pages/forgotPassword/ForgotPassword'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

function App() {
  const alert = useSelector(state => state.alert);
  //render() {
    return (
      <div>
        {alert.message && <Toast message={alert.message} />}
        <WebSocketProvider>
        <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/admin/login" name="Login Page" render={props => <Login {...props} />} />
              <Route exact path="/forgot-password" name="Forgot Password Page" render={props => <ForgotPassword {...props} />} />
              {/* <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} /> */}
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
              <PrivateRoute path="/" name="Home" render={props => <TheLayout {...props} />} />
            </Switch>
          </React.Suspense>
        </HashRouter>
        </WebSocketProvider>
      </div>
    );
  //}
}

export default App;
