import { authHeader, handleResponse } from "../helpers";
export const userService = {
  login,
  getCustomers,
  getById,
  update,
  _delete,
  getPerformers,
  getPerformerById,
  getAreaOfConcentration,
  logout,
  getProfile,
  getUVDropDown,
  getReferrerDropdown,
  retrieveAccountsList,
  addBankDetails,
  updateBankDetails,
  setAsDefaultAccount,
  deleteBankAccount,
  forgotPassword
};

function login(user) {
	let formBody = [];
	for (let property in user) {
		let encodedKey = encodeURIComponent(property);
		let encodedValue = encodeURIComponent(user[property]);
		formBody.push(encodedKey + "=" + encodedValue);
	}
	
	formBody = formBody.join("&");
	
	const requestOptions = {
		method: "POST",
		headers: { "Content-Type": "application/x-www-form-urlencoded" },
		body: formBody,
	};
	let res = fetch(`${process.env.REACT_APP_API_URL}auth/local`, requestOptions)
		.then(handleResponse)
		.then((data) => {
		// store user details and jwt token in local storage to keep user logged in between page refreshes
		localStorage.setItem("token", data.token);
		localStorage.setItem("user", JSON.stringify(data));
		localStorage.setItem("rememberMe", user.rememberMe);
		localStorage.setItem("key", user.password);
		return data;
		});
	return res;
}

function getCustomers(params) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let url = new URL(`${process.env.REACT_APP_API_URL}api/v1/users`);
  if (params.role) {
    url.search = new URLSearchParams({
      offset: params.offset,
      limit: params.limit,
      role: params.role,
      keyword: params.keyword,
    });
  }
  return fetch(url, requestOptions).then(handleResponse);
}

function getUVDropDown(params) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let url = new URL(`${process.env.REACT_APP_API_URL}api/v1/users`);
  url.search = new URLSearchParams({
    role: params.role,
  });
  return fetch(url, requestOptions).then(handleResponse);
}

function getReferrerDropdown() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let url = new URL(`${process.env.REACT_APP_API_URL}api/v1/referrer_list`);
  return fetch(url, requestOptions).then(handleResponse);
}

function getProfile() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let url = new URL(`${process.env.REACT_APP_API_URL}api/v1/users/me`);
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      const { _id, role } = data?.data;
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem("role", role);
      localStorage.setItem("dataId", _id);
      return data;
    });
}

function getPerformers(params) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let url = new URL(`${process.env.REACT_APP_API_URL}api/v1/performers`);
  url.search = new URLSearchParams(params);
  return fetch(url, requestOptions).then(handleResponse);
}

function getById(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}api/v1/users/${id}`,
    requestOptions
  ).then(handleResponse);
}

function getPerformerById(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}api/v1/performers/${id}`,
    requestOptions
  ).then(handleResponse);
}

function getAreaOfConcentration() {
  const requestOptions = {
    method: "GET",
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}api/v1/area_of_concentrations`,
    requestOptions
  ).then(handleResponse);
}

function update(user, id, type) {
    let formData;
    if (type != "performers") {
      const form = new FormData();
      for (let key in user) {
        form.append(key, user[key]);
      }
      formData = form;
    } else {
      formData = user;
    }
    const requestOptions = {
      method: "PUT",
      body: formData,
      headers: authHeader(),
    };
    return fetch(
      `${process.env.REACT_APP_API_URL}api/v1/${type}/${id}`,
      requestOptions
    ).then(handleResponse);
}

function _delete(id, type) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}api/v1/${type}/${id}`,
    requestOptions
  ).then(handleResponse);
}

function retrieveAccountsList(userType) {
	const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}api/v1/${userType}/retrieveAccountInformation`,
    requestOptions
  ).then(handleResponse);
}

function logout() {
  localStorage.clear();
}




function addBankDetails(data) {
    const form = new FormData();

    for (let key in data) {
        form.append(key, data[key]);
    }
    const requestOptions = {
        method: "POST",
        body: form,
        headers: authHeader(),
    };
    let url =`${process.env.REACT_APP_API_URL}api/v1/users/attachBankAccount`;

    return fetch(`${url}`, requestOptions).then(handleResponse);
}

function updateBankDetails(data) {

	const form = new FormData();
  
	for (let key in data) {
		form.append(key, data[key]);
	}

	const requestOptions = {
        method: "POST",
        body: form,
        headers: authHeader(),
    };
	
	  const url = `${process.env.REACT_APP_API_URL}/api/v1/user/updateBankAccount`
	  return fetch(`${url}`, requestOptions).then(handleResponse);
  }

function setAsDefaultAccount(accountId) {
	
	const requestOptions = {
        method: "PUT",
        headers: authHeader(),
    };
	
	const url = `${process.env.REACT_APP_API_URL}/api/v1/user/setAccountAsDefault/${accountId}`;
	return fetch(`${url}`, requestOptions).then(handleResponse);
	
}


function deleteBankAccount(accountId) {
  
	const requestOptions = {
        method: "DELETE",
        headers: authHeader(),
    };

	const url = `${process.env.REACT_APP_API_URL}/api/v1/user/deleteSavedAccount/${accountId}`;
	return fetch(`${url}`, requestOptions).then(handleResponse);
}

function forgotPassword(data) {
	let formBody = [];
	for (let property in data) {
		let encodedKey = encodeURIComponent(property);
		let encodedValue = encodeURIComponent(data[property]);
		formBody.push(encodedKey + "=" + encodedValue);
	}
	formBody = formBody.join("&");
	const requestOptions = {
		method: "POST",
		headers: { "Content-Type": "application/x-www-form-urlencoded" },
		body: formBody,
	};
	return fetch(`${process.env.REACT_APP_API_URL}api/v1/users/forgot`, requestOptions).then(handleResponse);
}
