import React from 'react';
import FormatDateInAmPm from './FormatDateInAmPm';
const periods = {
  month: 30 * 24 * 60 * 60 * 1000,
  week: 7 * 24 * 60 * 60 * 1000,
  day: 24 * 60 * 60 * 1000,
  hour: 60 * 60 * 1000,
  minute: 60 * 1000
};
const TimeAgo = (timeCreated) => {
		let date = new Date(timeCreated)
        var diff = Date.now() - date;
		let currentdate = new Date();
        var previousDate = (currentdate.getDate() -1) == date.getDate() && currentdate.getMonth() == date.getMonth() && currentdate.getFullYear() == date.getFullYear()
		  if (diff > periods.month) {
		    // it was at least a month ago
		    return Math.floor(diff / periods.month) + "m";
		  } else if (diff > periods.week) {
		    return Math.floor(diff / periods.week) + "w";
		  } else if (diff > periods.day && !previousDate) {
		    return Math.floor(diff / periods.day) + "d";
		    // return 'Yesterday';
		  } else if (previousDate) {
		    //return Math.floor(diff / periods.day) + "d";
		    return 'Yesterday';
		  } else if (diff > periods.hour) {
		  	return FormatDateInAmPm(timeCreated);
		    //return Math.floor(diff / periods.hour) + "h";
		  } else if (diff > periods.minute) {
		    return Math.floor(diff / periods.minute) + "m";
		  }
		  return "Just now";
    }
export default TimeAgo;