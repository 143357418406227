import { authHeader } from "../helpers";
import { saveAs } from "file-saver";

export const subscriberService = {
    guestList,
    exportGuestList,
    subscriberList,
};

function guestList(params) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    let url = new URL(`${process.env.REACT_APP_API_URL}api/v1/subscribe`);
    url.search = new URLSearchParams({
        offset: params.offset,
        limit: params.limit,
        keyword: params.keyword,
    });
    return fetch(url, requestOptions).then(handleResponse);
}

function subscriberList(params) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    let url = new URL(`${process.env.REACT_APP_API_URL}api/v1/subscribers`);
    url.search = new URLSearchParams({
        offset: params.offset,
        limit: params.limit,
        keyword: params.keyword,
    });
    return fetch(url, requestOptions).then(handleResponse);
}

function exportGuestList(params) {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    let url = new URL(`${process.env.REACT_APP_API_URL}api/v1/export-csv`);
    // url.search = new URLSearchParams({
    //     status: params.status,
    // });
    return fetch(url, requestOptions)
        .then((res) => res.blob())
        .then((blob) => {
            var FileSaver = require("file-saver");
            const bufferData = new Blob([blob], {
                type: "text/csv;charset=utf-8"
            });
            FileSaver.saveAs(bufferData, "subscribers.csv");
        });
}

function handleResponse(response) {
    return response.json().then((text) => {
        const data = text;
        if (!response.ok) {
            /* if (response.status === 401) {
                      // auto logout if 401 response returned from api
                      //logout();
                      //location.reload(true);
                  } */

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}