import { authHeader,handleResponse } from '../helpers';

export const reviewService = {
    _delete,
}


function _delete(id) {
    
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${process.env.REACT_APP_API_URL}api/v1/review/${id}`, requestOptions).then(handleResponse);
}