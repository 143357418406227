import { homeConstants } from '../constants';
import { homeService, settingService } from '../services';
import { alertActions } from './';

export const homeActions = {
    index,
    create,
    _delete,
    getAreaList,
    update
}

function index() {  
    return dispatch => {
        return new Promise((resolve, reject) => {
            homeService.index()
            .then(
                data => {
                    dispatch(success(data));
                    resolve(data)
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    reject()
                }
            );
        });
    };

    function success(data) { return { type: homeConstants.GET_LIST, payload:data } }
}

function getAreaList() {  
    return dispatch => {
        return new Promise((resolve, reject) => {
            settingService.getList()
            .then(
                data => {
                    dispatch(success(data));
                    resolve(data)
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    reject()
                }
            );
        });
    };

    function success(data) { return { type: homeConstants.GET_AREA_LIST, payload:data } }
}

function create(request) {  
    return dispatch => {
        return new Promise((resolve, reject) => {
            homeService.create(request)
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(alertActions.success(data.message));
                    resolve(data)
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    reject()
                }
            );
        });
    };

    function success(data) { return { type: homeConstants.GET_HOME_SUCCESS, payload:data } }
}

function _delete(id) {
    return dispatch => {
            homeService._delete(id)
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(alertActions.success(data && data.message[0]));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function success(data) { return { type: homeConstants.REMOVE_HOME_SUCCESS, payload:data } }
}


function update(data) {
    return dispatch => {
        homeService.update(data).then(
            res=> {
                //dispatch(success(res))
            },
            error => {
                dispatch(alertActions.error(error.toString()));
            }
        )
    }
    function success(data) { return { type: homeConstants.GET_LIST, payload:data } }
}