import React from 'react';
import FormatDateInAmPm from './FormatDateInAmPm';
import FormatDate from './FormatDate';

  const FormatDateTimeMessage = (timeCreated) =>
      {
		let date = new Date(timeCreated)
		let currentdate = new Date();
        var today = currentdate.getDate() == date.getDate() && currentdate.getMonth() == date.getMonth() && currentdate.getFullYear() == date.getFullYear()
        var previousDate = (currentdate.getDate() -1) == date.getDate() && currentdate.getMonth() == date.getMonth() && currentdate.getFullYear() == date.getFullYear()
        if(today){
            return FormatDateInAmPm(timeCreated);
        }else if (previousDate){
            return `Yesterday | ${FormatDateInAmPm(timeCreated)}`;
        }else {
            return `${FormatDate(timeCreated)} | ${FormatDateInAmPm(timeCreated)}`;
        }
    }
export default FormatDateTimeMessage;