import { galleryConstants } from '../constants';
import { galleryService } from '../services';
import { alertActions } from './';

export const galleryActions = {
    index,
    _delete,
}

function index(request) {
    
    return dispatch => {
        return new Promise((resolve, reject) => {
            galleryService.index(request)
            .then(
                data => {
                    dispatch(success(data));
                    resolve(data)
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    reject()
                }
            );
        });
    };

    function success(data) { return { type: galleryConstants.GET_GALLERY_SUCCESS, payload:data } }
}

function _delete(id) {
    return dispatch => {
            galleryService._delete(id)
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(alertActions.success(data && data.message[0]));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function success(data) { return { type: galleryConstants.REMOVE_GALLERY_SUCCESS, payload:data } }
}