import { settingConstants, uvRepConstants } from '../constants';
import { messageConstants, uvNewsConstants } from '../constants';
import { uvRepService, uvNewsService } from '../services';
import { alertActions } from './';

export const uvNewsActions = {
    index, 
    create,
    update
};

function index(request) {
    return dispatch => {
        return new Promise((resolve, reject) => {
        uvNewsService.index(request)
            .then(
                data => {
                    dispatch(success(data));
                    resolve(data)
                },
                error => {
                    reject()
                }
            );
        });
    };

    function success(result) { return { type: uvNewsConstants.GET_SUCCESS, result } }
}

function create(user) {
    return dispatch => {
        return new Promise((resolve, reject) => {
        dispatch(request(user));
        uvNewsService.create(user)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success(user.message[0]));
                    resolve(user)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    reject()
                }
            );
        });
    };

    function request(user) { return { type: uvNewsConstants.ADD_REQUEST, user } }
    function success(user) { return { type: uvNewsConstants.ADD_SUCCESS, user } }
    function failure(error) { return { type: uvNewsConstants.ADD_FAILURE, error } }

}

function update(user, id) {
    return dispatch => {
        return new Promise((resolve, reject) => {
        dispatch(request());
        uvNewsService.update(user, id)
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(alertActions.success(data.message[0] ));
                    resolve(data)
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    reject()
                }
            );
        });
    };
    function request() { return { type: uvNewsConstants.UPDATE_REQUEST } }
    function success(result) { return { type: uvNewsConstants.UPDATE_SUCCESS, result } }
}
