import { settingConstants, userConstants } from "../constants";
import { messageConstants } from "../constants";
import { userService, settingService } from "../services";
import { alertActions } from "./";

export const settingActions = {
  add,
  update,
  _delete,
  groupChatList,
  getRecommendations,
  setRecommendations,
  getMarketRecommendations,
  setMarketRecommendations,
  fileDownload,
  getFull,
  addUpdateAdminFeatureAccordingEmail
};

function add(user) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(request(user));
      settingService.add(user).then(
        (user) => {
          dispatch(success(user));
          dispatch(alertActions.success(user.message[0]));
          resolve(user);
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
          reject();
        }
      );
    });
  };

  function request(user) {
    return { type: settingConstants.ADD_REQUEST, user };
  }
  function success(user) {
    return { type: settingConstants.ADD_SUCCESS, user };
  }
  function failure(error) {
    return { type: settingConstants.ADD_FAILURE, error };
  }
}

function update(user, id) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(request(user));
			settingService.update(user, id).then((data) => {
				dispatch(success(data, id));
				dispatch(alertActions.success(data.message[0]));
				resolve(data)
			}).catch((error) => {
				dispatch(failure(error.toString()));
				dispatch(alertActions.error(error.toString()));
				reject();
			});
		});
	};

	function request(user) {
		return { type: userConstants.UPDATE_REQUEST, user };
	}
	function success(user) {
		return { type: userConstants.UPDATE_SUCCESS, user };
	}
	function failure(error) {
		return { type: userConstants.UPDATE_FAILURE, error };
	}
}

function _delete(id) {
  return (dispatch) => {
    dispatch(request(id));
    settingService._delete(id).then(
      (response) => {
        dispatch(success(id));
        dispatch(alertActions.success(response.message[0]));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(id) {
    return { type: settingConstants.DELETE_REQUEST, id };
  }
  function success(id) {
    return { type: settingConstants.DELETE_SUCCESS, id };
  }
  function failure(error) {
    return { type: settingConstants.DELETE_FAILURE, error };
  }
}

function groupChatList(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      settingService.groupChatList(params).then(
        (response) => {
          dispatch(success(response));
          //dispatch(alertActions.success(response.message[0]));
          resolve(response);
        },
        (error) => {
          dispatch(alertActions.error(error.toString()));
          reject();
        }
      );
    });
  };

  function success(payload) {
    return { type: settingConstants.LIST_SUCCESS, payload };
  }
}

function setRecommendations(request) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      settingService.setRecommendations(request).then(
        (data) => {
          dispatch(alertActions.success("Recommendations Updated"));
          resolve(data);
        },
        (error) => {
          reject();
          dispatch(alertActions.error("Recommendations Update Failed"));
        }
      );
    });
  };
}

function getRecommendations(request) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      settingService.getRecommendations(request).then(
        (data) => {
          dispatch(success(data));
          resolve(data);
        },
        (error) => {
          reject();
        }
      );
    });
  };

  function success(result) {
    return { type: settingConstants.ADD_SUCCESS, result };
  }
}

function setMarketRecommendations(request) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      settingService.setMarketRecommendations(request).then(
        (data) => {
          dispatch(alertActions.success("Recommendations Updated"));
          resolve(data);
        },
        (error) => {
          reject();
          dispatch(alertActions.error("Recommendations Update Failed"));
        }
      );
    });
  };
}

function getMarketRecommendations(request) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      settingService.getMarketRecommendations(request).then(
        (data) => {
          dispatch(success(data));
          resolve(data);
        },
        (error) => {
          reject();
        }
      );
    });
  };

  function success(result) {
    return { type: settingConstants.ADD_SUCCESS, result };
  }
}

function fileDownload(fileName) {
  return dispatch => {
      return new Promise((resolve, reject) => {
          settingService.fileDownload(fileName).then((item) => {resolve(item)}).catch((error) => {
				dispatch(alertActions.error(error.toString()));
				reject();
		  })
      });
  };
}
function getFull() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(request());
      settingService.getFull().then(
        (response) => {
          dispatch(success(response));
          resolve(response);
        },
        (error) => {
          dispatch(failure());
          reject(error.toString());
        }
      );
    });
  };

  function request() {
    return { type: settingConstants.GET_FULL_SETTING_REQUEST };
  }
  function success(response) {
    return { type: settingConstants.GET_FULL_SETTING_SUCCESS, payload: response };
  }

  function failure() {
    return { type: settingConstants.GET_FULL_SETTING_FAILURE };
  }

}

function addUpdateAdminFeatureAccordingEmail(formData, emailId) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(request());
      settingService.addUpdateAdminFeatureAccordingEmail(formData, emailId).then(
        (response) => {
            dispatch(success(response?.data));
            dispatch(alertActions.success(response?.message));
            resolve(response);
        },
        (error) => {
            dispatch(alertActions.error(error.toString()));
            reject();
        }
      );
    });
  };

  function request() {
    return { type: settingConstants.GET_FULL_SETTING_REQUEST };
  }
  function success(response) {
    return { type: settingConstants.GET_FULL_SETTING_SUCCESS, payload: response };
  }

  function failure() {
    return { type: settingConstants.GET_FULL_SETTING_FAILURE };
  }

}