import { authHeader } from "../helpers";
export const settingService = {
  getList,
  add,
  getById,
  update,
  _delete,
  shoutoutList,
  getShoutById,
  storeOrderList,
  liveOneOnOneList,
  shareNewsFeed,
  groupChatList,
  promotionProductOrderList,
  promotionProductOrderById,
  setRecommendations,
  getRecommendations,
  setMarketRecommendations,
  getMarketRecommendations,
  fileDownload,
  getFull,
  addUpdateAdminFeatureAccordingEmail,
  getOrderDetails,
};

function getList(params) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let url = new URL(
    `${process.env.REACT_APP_API_URL}api/v1/area_of_concentrations`
  );
  return fetch(url, requestOptions).then(handleResponse);
}

function shoutoutList(params) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let url = new URL(
    `${process.env.REACT_APP_API_URL}api/v1/requestShoutOut/list`
  );
  url.search = new URLSearchParams({
    offset: params.offset,
    limit: params.limit,
    userId: params.userId,
    performerId: params.performerId,
  });
  return fetch(url, requestOptions).then(handleResponse);
}

function getShoutById(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}api/v1/requestShoutOut/${id}`,
    requestOptions
  ).then(handleResponse);
}

function storeOrderList(params) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let url = new URL(
    `${process.env.REACT_APP_API_URL}api/v1/shopify/all-orders`
  );
  url.search = new URLSearchParams({
    offset: params.offset,
    limit: params.limit,
    keyword: params.keyword,
    type: params?.type || null
  });
  return fetch(url, requestOptions).then(handleResponse);
}

function promotionProductOrderList(params) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let url = new URL(
    `${process.env.REACT_APP_API_URL}api/v1/product-promotion-request/list`
  );
  url.search = new URLSearchParams({
    offset: params.offset,
    limit: params.limit,
    keyword: params.keyword,
  });
  return fetch(url, requestOptions).then(handleResponse);
}

function promotionProductOrderById(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}api/v1/product-promotion-request/${id}`,
    requestOptions
  ).then(handleResponse);
}

function liveOneOnOneList(params) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let url = new URL(`${process.env.REACT_APP_API_URL}api/v1/liveChatRequests`);
  url.search = new URLSearchParams({
    offset: params.offset,
    limit: params.limit,
    keyword: params.keyword,
  });
  return fetch(url, requestOptions).then(handleResponse);
}

function add(user) {
  let formBody = [];
  for (let property in user) {
    let encodedKey = encodeURIComponent(property);
    let encodedValue = encodeURIComponent(user[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: formBody,
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}api/v1/area_of_concentrations`,
    requestOptions
  ).then(handleResponse);
}

function getById(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}api/v1/area_of_concentrations/${id}`,
    requestOptions
  ).then(handleResponse);
}

function update(user, id) {
  let formBody = [];
  for (let property in user) {
    let encodedKey = encodeURIComponent(property);
    let encodedValue = encodeURIComponent(user[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  const requestOptions = {
    method: "PUT",
    headers: {
      ...authHeader(),
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: formBody,
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}api/v1/area_of_concentrations/${id}`,
    requestOptions
  ).then(handleResponse);
}

function _delete(id) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}api/v1/area_of_concentrations/${id}`,
    requestOptions
  ).then(handleResponse);
}

function shareNewsFeed(user) {
  let formBody = [];
  for (let property in user) {
    let encodedKey = encodeURIComponent(property);
    let encodedValue = encodeURIComponent(user[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: formBody,
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}api/v1/uv-news-feed`,
    requestOptions
  ).then(handleResponse);
}

function groupChatList(params) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let url = new URL(`${process.env.REACT_APP_API_URL}api/v1/streams`);
  url.search = new URLSearchParams({
    offset: params.offset,
    limit: params.limit,
  });
  return fetch(url, requestOptions).then(handleResponse);
}

function getRecommendations() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}api/v1/store/recomendation`,
    requestOptions
  ).then(handleResponse);
}
function setRecommendations(data) {
  let formBody = [];
  for (let property in data) {
    let encodedKey = encodeURIComponent(property);
    let encodedValue = encodeURIComponent(data[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: formBody,
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}api/v1/store/recomendation`,
    requestOptions
  ).then(handleResponse);
}
function getMarketRecommendations() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}api/v1/marketplace/recomendation`,
    requestOptions
  ).then(handleResponse);
}
function setMarketRecommendations(data) {
  let formBody = [];
  for (let property in data) {
    let encodedKey = encodeURIComponent(property);
    let encodedValue = encodeURIComponent(data[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: formBody,
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}api/v1/marketplace/recomendation`,
    requestOptions
  ).then(handleResponse);
}

function handleResponse(response) {
  return response.json().then((text) => {
    const data = text;
    if (!response.ok) {
      /* if (response.status === 401) {
                // auto logout if 401 response returned from api
                //logout();
                //location.reload(true);
            } */

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}

function fileDownload(fileName) {
  const requestOptions = {
      method: 'GET',
      headers: authHeader()
  };
  return fetch(`${process.env.REACT_APP_API_URL}api/v1/download/s3?key=${fileName}`, requestOptions).then(handleResponse)
}

function getFull() {
  const requestOptions = {
      method: 'GET',
      headers: authHeader()
  };
  return fetch(`${process.env.REACT_APP_API_URL}api/v1/settings/get/full`, requestOptions).then(handleResponse)
}

function addUpdateAdminFeatureAccordingEmail(data, emailId) {
  /* let formBody = [];
  for (let property in data) {
    let encodedKey = encodeURIComponent(property);
    let encodedValue = encodeURIComponent(data[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&"); */
  const requestOptions = {
      method: 'PUT',
      headers: authHeader(),
      body: data
  };
  return fetch(`${process.env.REACT_APP_API_URL}api/v1/settings/${emailId}`, requestOptions)
      .then(handleResponse);
}

function getOrderDetails(orderId) {
  const requestOptions = {
      method: 'GET',
      headers: authHeader()
  };
  return fetch(`${process.env.REACT_APP_API_URL}api/v1/shopify/orders/${orderId}`, requestOptions).then(handleResponse)
}



/* 
----------------
meetgreetadminemail:"harwindersingh+123+admin@zapbuild.com" */