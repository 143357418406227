export const directMessageConstants = {
  GET_DM_SUCCESS:  'GET_DM_SUCCESS',
  GET_MESSAGE_SUCCESS: 'GET_MESSAGE_SUCCESS',
  GET_ADD_MESSAGE_SUCCESS: 'GET_ADD_MESSAGE_SUCCESS',
  DELETE_MESSAGE: 'DELETE_MESSAGE',
  DELETE_REQUEST: 'DELETE_REQUEST',
  NEW_CHAT: 'NEW_CHAT',
  GET_ADD_NEW_MESSAGE: 'GET_ADD_NEW_MESSAGE',
  ACTIVE_USER: 'ACTIVE_USER',
  ROLES: ["merchant", "user", "admin", "performer", "referrer", "uv-rep"],

};
