import { authHeader, handleResponse } from "../helpers";

export const directMessageService = {
  list,
  getDetails
};

function list(params) {
  let paramOpt = {};
  for (let key in params) {
    if (params[key] !== "") {
      paramOpt[key] = params[key];
    }
  }
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let url = new URL(
    `${process.env.REACT_APP_API_URL}api/v1/chat/list`
  );
  url.search = new URLSearchParams(paramOpt);
  return fetch(url, requestOptions).then(handleResponse);
}

function getDetails(params) {
  let paramOpt = {};
  for (let key in params) {
    if (params[key] !== "") {
      paramOpt[key] = params[key];
    }
  }
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let url = new URL(
    `${process.env.REACT_APP_API_URL}api/v1/chat/${paramOpt.id}`
  );
  return fetch(url, requestOptions).then(handleResponse);
}