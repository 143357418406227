import { faqConstants } from "../constants";
import { faqService } from "../services";
import { alertActions } from ".";

export const faqActions = {
  index,
  show,
  update,
  _delete,
  store,
};

function index(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      faqService.index(params).then(
        (data) => {
          dispatch(success(data));
          resolve(data);
        },
        (error) => {
          dispatch(alertActions.error(error.toString()));
          reject();
        }
      );
    });
  };

  function success(data) {
    return { type: faqConstants.GET_FAQ_SUCCESS, payload: data };
  }
}

function show(id) {
  return faqService.show(id);
}

function update(request, id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      faqService.update(request, id).then(
        (data) => {
          dispatch(success(data));
          dispatch(alertActions.success(data.message));
          resolve(data);
        },
        (error) => {
          dispatch(alertActions.error(error.toString()));
          reject();
        }
      );
    });
  };

  function success(data) {
    return { type: faqConstants.UPDATE_FAQ_SUCCESS, payload: data.data };
  }
}

function _delete(id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      faqService._delete(id).then(
        (data) => {
          dispatch(success(data));
          dispatch(alertActions.success(data.message));
          resolve(data);
        },
        (error) => {
          dispatch(alertActions.error(error.toString()));
          reject();
        }
      );
    });
  };

  function success(data) {
    return { type: faqConstants.REMOVE_FAQ_SUCCESS, payload: data };
  }
}

function store(request) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      faqService.store(request).then(
        (data) => {
          dispatch(success(data));
          dispatch(alertActions.success(data.message));
          resolve(data);
        },
        (error) => {
          dispatch(alertActions.error(error.toString()));
          reject();
        }
      );
    });
  };

  function success(data) {
    return {
      type: faqConstants.STORE_FAQ_SUCCESS,
      payload: data.item,
    };
  }
}
