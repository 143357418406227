import { uvRepConstants } from '../constants';

const initialState = {};
export function uvRep(state = initialState, action) {
  switch (action.type) {
    case uvRepConstants.ADD_REQUEST:
      return {
        user: action.user,
      };
    case uvRepConstants.ADD_SUCCESS:
      return {
        token: action.user && action.user.token,
        user: action.user
      };
    case uvRepConstants.ADD_FAILURE:
      return {};
    case uvRepConstants.DELETE_REQUEST:
      return {
        submitted: true,
      };
    case uvRepConstants.DELETE_SUCCESS:
      return {
        deleted: true
      };
    case uvRepConstants.DELETE_FAILURE:
      return {};
    default:
      return state
  }
}