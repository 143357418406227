import { chatConstants } from '../constants';
import { chatService } from '../services';
import { alertActions } from './';

export const chatActions = {
    index,
    add,
    _delete,
    getById,
    getUnreadMsg
}

function index(request) {
    
    return dispatch => {
        return new Promise((resolve, reject) => {
            chatService.index(request)
            .then(
                data => {
                    dispatch(success(data));
                    resolve(data)
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    reject()
                }
            );
        });
    };

    function success(data) { return { type: chatConstants.GET_CHAT_SUCCESS, payload:data } }
}

function getUnreadMsg() {
    return dispatch => {
        return new Promise((resolve, reject) => {
            chatService.getUnreadMsg()
            .then(
                data => {
                    resolve(data)
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    reject()
                }
            );
        });
    };
}

function add(request, conversationId) {
    
    return dispatch => {
        return new Promise((resolve, reject) => {
            chatService.add(request)
            .then(
                data => {
                    //dispatch(success(data,conversationId));
                    resolve(data)
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    reject()
                }
            );
        });
    };

    //function success(data,conversationId) { return { type: chatConstants.GET_ADD_MESSAGE_SUCCESS, item:data,conversationId:conversationId  } }
}

function _delete(id) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request(id));
            chatService._delete(id)
            .then(
                data => {
                    dispatch(success(id));
                    dispatch(alertActions.success(data && data.message[0]))
                    resolve(data)
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    reject()
                }
            );
        });
    };

    function request(id) { return { type: chatConstants.DELETE_REQUEST, id:id } }
    function success(id) { return { type: chatConstants.DELETE_MESSAGE, id:id } } 
}

function getById(id) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            chatService.getById(id)
            .then(
                data => {
                    dispatch(success(data));
                    resolve(data)
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    reject()
                }
            );
        });
    };

    function success(data) { return { type: chatConstants.GET_MESSAGE_SUCCESS, payload:data } }
}