import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import {
    CToast,
    CToastBody,
    CToastHeader,
    CToaster,
} from '@coreui/react'

const Toast = (props) => {
    const alert = useSelector(state => state.alert);
    const positions = [
        'static',
        'top-left',
        'top-center',
        'top-right',
        'top-full',
        'bottom-left',
        'bottom-center',
        'bottom-right',
        'bottom-full'
    ]

    const [toasts, setToasts] = useState([
        /* {
            position: 'top-right',
            autohide: 3000
        } */
    ])

    const [position, setPosition] = useState('top-right')
    const [autohide, setAutohide] = useState(true)
    const [autohideValue, setAutohideValue] = useState(2000)
    const [closeButton, setCloseButton] = useState(true)
    const [fade, setFade] = useState(true)
    const [message, setMessage] = useState(alert.message);

    useEffect(() => {
        setMessage(alert.message)
    }, [alert])

    useEffect(() => {
        setToasts([
            ...toasts,
            { position, autohide: autohide && autohideValue, closeButton, fade, message }
        ])
    }, [alert])

    const toasters = (() => {
        return toasts.reduce((toasters, toast) => {
            toasters[toast.position] = toasters[toast.position] || []
            toasters[toast.position].push(toast)
            return toasters
        }, {})
    })()

    return (<div>
        {Object.keys(toasters).map((toasterKey) => (
            <CToaster
                position={toasterKey}
                key={'toaster' + toasterKey}
            >
                {
                    toasters[toasterKey].map((toast, key) => {
                        return (
                            <CToast
                                key={'toast' + key}
                                show={true}
                                autohide={toast.autohide}
                                fade={toast.fade}
                            >
                                <CToastBody className={alert.type}>
                                    {message}
                                </CToastBody>
                            </CToast>
                        )
                    })
                }
            </CToaster>
        ))}
    </div>
    )
}

export default Toast;
