import { authHeader, handleResponse } from '../helpers';

export const tosService = {
  index,
  remove,
  show,
  update,
  create,
};

function index(params) {
    
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    let url = new URL(`${process.env.REACT_APP_API_URL}api/v1/terms-of-service`);
    url.search = new URLSearchParams(params);

    return fetch(url, requestOptions).then(handleResponse);
}

function remove(id) {
    
    const requestOptions = {
        method: "DELETE",
        headers: authHeader(),
    };
    let url = new URL(`${process.env.REACT_APP_API_URL}api/v1/terms-of-service/${id}`);
    
    return fetch(url, requestOptions).then(handleResponse);
}

function show(id) {

    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    let url = new URL(`${process.env.REACT_APP_API_URL}api/v1/terms-of-service/${id}`);
    
    return fetch(url, requestOptions).then(handleResponse);

}

function update(request,id) {

    let formBody = [];

    for (let property in request) {
        if (Array.isArray(request[property])) {
            for (let i = 0; i < request[property].length; i++) {
                let encodedKey = encodeURIComponent(property);
                let encodedValue = encodeURIComponent(request[property][i]);
                formBody.push(encodedKey + "=" + encodedValue);
            }
        } else {
            let encodedKey = encodeURIComponent(property);
            let encodedValue = encodeURIComponent(request[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
    }
    formBody = formBody.join("&");

    const requestOptions = {
        method: 'PUT',
        headers: {...authHeader(), 'Content-Type': 'application/x-www-form-urlencoded' },
        body: formBody
    };

    let url = new URL(`${process.env.REACT_APP_API_URL}api/v1/terms-of-service/${id}`);
    return fetch(url, requestOptions).then(handleResponse);

}

function create(request) {

    let formBody = [];

    for (let property in request) {
        if (Array.isArray(request[property])) {
            for (let i = 0; i < request[property].length; i++) {
                let encodedKey = encodeURIComponent(property);
                let encodedValue = encodeURIComponent(request[property][i]);
                formBody.push(encodedKey + "=" + encodedValue);
            }
        } else {
            let encodedKey = encodeURIComponent(property);
            let encodedValue = encodeURIComponent(request[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
    }
    formBody = formBody.join("&");

    const requestOptions = {
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/x-www-form-urlencoded' },
        body: formBody
    };

    return fetch(`${process.env.REACT_APP_API_URL}api/v1/terms-of-service`, requestOptions).then(handleResponse);

}