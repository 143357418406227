import React, { createContext } from "react";
import io from "socket.io-client";
import { useDispatch } from "react-redux";

const WebSocketContext = createContext(null);
const SOCKET_IO_URL = process.env.REACT_APP_SOCKET_URL;

export { WebSocketContext };

export default ({ children }) => {
  let socket;
  let ws;

  const dispatch = useDispatch();

  if (!socket) {
    socket = io(SOCKET_IO_URL, {
      query: "token=" + localStorage.getItem("token"),
      transports: ["websocket"],
      secure: true,
    });
    ws = {
      socket: socket,
    };
  }

  if (socket && socket.disconnected) {
    socket = io(SOCKET_IO_URL, {
      query: "token=" + localStorage.getItem("token"),
      transports: ["websocket"],
      secure: true,
    });
    ws = {
      socket: socket,
    };
  }

  return (
    <WebSocketContext.Provider value={ws}>{children}</WebSocketContext.Provider>
  );
};
