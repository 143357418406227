import { faqConstants } from '../constants';

const initialState = { list: [{ name: ' ', description: 'Loading...', totalProduct: ' ', action: ' ' }] };

export function faq(state = initialState, action) {

    switch (action.type) {
        case faqConstants.GET_FAQ_SUCCESS:
            return {
                ...state,
                list: action.payload && action.payload.Faqs ?
                    action.payload.Faqs : [],
            };
        case faqConstants.UPDATE_FAQ_SUCCESS:
            let data = state.list,
                index = data.findIndex((r) => r._id === action.payload._id);
            if (index > -1) {
                data[index] = action.payload;
                return {...state, list: data };
            } else {
                return state;
            }
        case faqConstants.ADD_FAQ_SUCCESS:
            return {
                ...state,
                list: state.list.concat(action.payload),
            };
        case faqConstants.REMOVE_FAQ_SUCCESS:
            return {
                ...state,
                list: state.list.filter(function(item) {
                    return item._id !== action.payload.id;
                }),
            };
        default:
            return state;
    }

}