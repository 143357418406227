import { authHeader, handleResponse } from '../helpers';
export const marketPlaceService = {
    index,
};

function index(params) {
    const requestOptions = {
        method: 'GET',
    };
    let url = new URL(`${process.env.REACT_APP_API_URL}api/v1/marketPlace/getBundles`)
    url.search = new URLSearchParams(params)
    return fetch(url, requestOptions).then(handleResponse);
}