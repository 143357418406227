import { authHeader,handleResponse } from '../helpers';

export const galleryService = {
    index,
    _delete
}

function index(request) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = new URL(`${process.env.REACT_APP_API_URL}api/v1/admin-gallery`)
    url.search = new URLSearchParams({
        offset: request.offset,
        limit: request.limit,
        keyword: request.keyword
    })
    return fetch(url, requestOptions).then(handleResponse);
}

function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/v1/videos/${id}`, requestOptions).then(handleResponse);
}   