import { meetAndGreetConstants } from '../constants';

const initialState = {
    list: [],
    eventDetail: {},
    orderList: [],
    orderDetail: {}
};

export function meetAndGreet(state = initialState, action) {

    switch (action.type) {
        case meetAndGreetConstants.GET_MEET_AND_GREET_LIST_SUCCESS:
            return {
                ...state,
                list: action?.payload?.data ?? [],
            };
        case meetAndGreetConstants.GET_MEET_AND_GREET_EVENT_DETAIL_SUCCESS:
            return {
                ...state,
                eventDetail: action?.payload?.data ?? {}
            };
        case meetAndGreetConstants.GET_MEET_AND_GREET_ORDER_LIST_SUCCESS:
            return {
                ...state,
                orderList: action?.payload?.data ?? [],
            };
        case meetAndGreetConstants.GET_MEET_AND_GREET_ORDER_DETAIL_SUCCESS:
            return {
                ...state,
                orderDetail: action?.payload?.data ?? {}
            }
        case meetAndGreetConstants.UPDATE_MEET_AND_GREET_EVENT_SUCCESS:
            return {
                ...state,
                eventDetail: action?.payload
            }
        case meetAndGreetConstants.UPDATE_MEET_AND_GREET_ORDER_SUCCESS:
            return {
                ...state,
                orderDetail: action?.payload
            }
        default:
            return state;
    }

}