export const settingConstants = {
    ADD_REQUEST:  'ADD_REQUEST',
    ADD_SUCCESS: 'ADD_SUCCESS',
    ADD_FAILURE: 'ADD_FAILURE', 
    
    DELETE_REQUEST:  'DELETE_REQUEST',
    DELETE_SUCCESS: 'DELETE_SUCCESS',
    DELETE_FAILURE: 'DELETE_FAILURE',

    LIST_SUCCESS: 'LIST_SUCCESS',

    GET_FULL_SETTING_REQUEST: 'GET_FULL_SETTING_REQUEST',
    GET_FULL_SETTING_SUCCESS: 'GET_FULL_SETTING_SUCCESS',
    GET_FULL_SETTING_FAILURE: 'GET_FULL_SETTING_FAILURE',
};