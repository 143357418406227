import { commissionConstants } from '../constants';

const initialState = { commissionList: [{category: ' ',uvCommissionPercent: ' ',celebencerCommissionPercent: 'Loading.... ',referrerCommissionPercent: ' ', action: ' '}] };

export function commission(state = initialState,action) {

    switch(action.type) {
        case commissionConstants.GET_COMMISSION_SUCCESS:
        return {
            ...state,
            commissionList:action.payload && action.payload.commissions?action.payload.commissions:[],	
        };
        case commissionConstants.UPDATE_COMMISSION_SUCCESS:
            
            let data = state.commissionList,
            index = data.findIndex(r => r._id === action.payload._id) 
            if(index > -1) {
              data[index] = action.payload
              return {...state,commissionList: data}
            } else { 
                return state
            }
        default:
            return state;
    }

}