import { categoryConstants } from '../constants';

const initialState = { categoryList: [{name: ' ', description: 'Loading...',totalProduct: ' ',action: ' '}] };

export function category(state = initialState,action) {

    switch(action.type) {
        
        case categoryConstants.GET_CATEGORIES_SUCCESS:
            return {
                ...state,
                categoryList:action.payload && action.payload.productCategories?action.payload.productCategories:[],	
            };
        case categoryConstants.UPDATE_CATEGORY_SUCCESS:
            let data = state.categoryList,
            index = data.findIndex(r => r._id === action.payload._id) 
            if(index > -1) {
              data[index] = action.payload
              return {...state,categoryList: data}
            } else { 
                return state
            }
        case categoryConstants.ADD_CATEGORY_SUCCESS:
            
            return {
                ...state,
                categoryList:state.categoryList.concat(action.payload),	
            };
        case categoryConstants.REMOVE_CATEGORY_SUCCESS:
            console.log("action.payload", action.payload);
            return {
				...state,
				categoryList:state.categoryList.filter(function(item) {
					return item._id !== action.payload.id
				}),	
			};
        default:
            return state;
    
    }

}