import { industryConstants } from '../constants';
import {industryService} from '../services';
import { alertActions } from './';

export const industryActions = {
    index,
    show,
    update,
    _delete,
    store,
}

function index() {
    return dispatch => {
        return new Promise((resolve, reject) => {
            industryService.index().then(
                data => {
                    dispatch(success(data));
                    resolve(data)
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    reject()
                }
            );
        });
    };

    function success(data) { return { type: industryConstants.GET_INDUSTRIES_SUCCESS, payload:data } }
}

function show(id) {
    return industryService.show(id);
}

function update(request, id) {
    
    return dispatch => {
        return new Promise((resolve, reject) => {
        industryService.update(request,id)
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(alertActions.success(data.message[0]));
                    resolve(data)
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    reject()
                }
            );
        });
    };

    function success(data) { return { type: industryConstants.UPDATE_INDUSTRY_SUCCESS, payload:data } }
}

function _delete(id) {
    return dispatch => {
        return new Promise((resolve, reject) => {
        industryService._delete(id)
            .then(
                data => {
                    dispatch(success({id: id}));
                    dispatch(alertActions.success(data.message[0]));
                    resolve(data)
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    reject()
                }
            );
        });
    };
    function success(data) { return { type: industryConstants.REMOVE_INDUSTRY_SUCCESS, payload:data } }
}

function store(request) {
    return dispatch => {
        return new Promise((resolve, reject) => {
        industryService.store(request)
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(alertActions.success(data.message[0]));
                    resolve(data)
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    reject()
                }
            );
        });
    };
    function success(data) { return { type: industryConstants.STORE_INDUSTRY_SUCCESS, payload:data.item } }
}