import { alertActions } from '.';
import { reviewConstants } from 'src/constants';
import { reviewService } from 'src/services';

export const reviewActions = {
    _delete
}

function _delete(id) {

    return dispatch => {
        return new Promise((resolve, reject) => {
            reviewService._delete(id)
                .then(
                    data => {
                        dispatch(success(data));
                        dispatch(alertActions.success(data.message[0]));
                        resolve(data)
                    },
                    error => {
                        dispatch(alertActions.error(error.toString()));
                        reject()
                    }
                );
        });
    };

    function success(data) { return { type: reviewConstants.REMOVE_REVIEW_SUCCESS, payload: data } }
}