export const homeConstants = {
    GET_HOME_SUCCESS:  'GET_HOME_SUCCESS',

    SHOW_HOME_SUCCESS: 'SHOW_HOME_SUCCESS',

    UPDATE_HOME_SUCCESS: 'UPDATE_HOME_SUCCESS',
   
    REMOVE_HOME_SUCCESS: 'REMOVE_HOME_SUCCESS',

    GET_AREA_LIST: 'GET_AREA_LIST',

    GET_LIST: 'GET_LIST'
};