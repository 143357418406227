import { authHeader,handleResponse } from '../helpers';

export const commissionService = {
    getList,
    show,
    update,
}

function getList() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/v1/commissions`, requestOptions).then(handleResponse);
}

function show(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/v1/commissions/${id}`, requestOptions).then(handleResponse);
}

function update(request ,id) {
    
    let formBody = [];
    
    for (let property in request) {
        if (Array.isArray(request[property])) {
            for (let i = 0; i < request[property].length; i++) {
                let encodedKey = encodeURIComponent(property);
                let encodedValue = encodeURIComponent(request[property][i]);
                formBody.push(encodedKey + "=" + encodedValue);
            }
        } else {
            let encodedKey = encodeURIComponent(property);
            let encodedValue = encodeURIComponent(request[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
    }
    formBody = formBody.join("&");
    
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/x-www-form-urlencoded' },
        body:formBody
    };

    return fetch(`${process.env.REACT_APP_API_URL}api/v1/commissions/${id}`, requestOptions).then(handleResponse);
}