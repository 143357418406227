import { userConstants } from "../constants";
import { emailService } from "../services";
import { alertActions } from "./";

export const emailActions = {
  update,
};

function update(body, id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(request(body));
      emailService.update(body, id).then(
        (user) => {
          dispatch(success(user, id));
          dispatch(alertActions.success(user.message[0]));
          resolve(user);
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
          reject(error);
        }
      );
    })
  };

  function request(user) {
    return { type: userConstants.UPDATE_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.UPDATE_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.UPDATE_FAILURE, error };
  }
}
