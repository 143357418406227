import { directMessageConstants } from "../constants";
import { directMessageService } from "../services";
import { alertActions } from '.';

export const directMessageAction = {
    list,
    getDetails
}

function list(params) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            directMessageService.list(params).then(
                (data) => {
                    dispatch(success(data));
                    resolve(data);
                },
                (error) => {
                    dispatch(alertActions.error(error.toString()));
                    reject();
                }
            );
        });
    };

    function success(data) {
        return { type: directMessageConstants.GET_DM_SUCCESS, payload: data };
    }
}

function getDetails(params) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            directMessageService.getDetails(params).then(
                (data) => {
                    dispatch(success(data));
                    resolve(data);
                },
                (error) => {
                    dispatch(alertActions.error(error.toString()));
                    reject();
                }
            );
        });
    };

    function success(data) {
        return { type: directMessageConstants.GET_DM_SUCCESS, payload: data };
    }
}
