import { paConstants } from '../constants';

const initialState = { list: [{ name: ' ', description: 'Loading...', totalProduct: ' ', action: ' ' }] };

export function personalAppearnce(state = initialState, action) {

    switch (action.type) {
        case paConstants.GET_PA_SUCCESS:
            return {
                ...state,
                list: action.payload && action.payload.slots ?
                    action.payload.slots : [],
            };
        default:
            return state;
    }

}