import { settingConstants, uvRepConstants } from "../constants";
import { messageConstants } from "../constants";
import { uvRepService, settingService } from "../services";
import { alertActions } from "./";

export const uvRepActions = {
  add,
  update,
  _delete,
  attachBank,
  transactionListing,
};

function add(user) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(request(user));
      uvRepService.add(user).then(
        (user) => {
          dispatch(success(user));
          dispatch(alertActions.success(messageConstants.UVREP_ADD_SUCCESS));
          resolve(user);
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
          reject();
        }
      );
    });
  };

  function request(user) {
    return { type: uvRepConstants.ADD_REQUEST, user };
  }
  function success(user) {
    return { type: uvRepConstants.ADD_SUCCESS, user };
  }
  function failure(error) {
    return { type: uvRepConstants.ADD_FAILURE, error };
  }
}

function update(user, id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(request(user));
      uvRepService.update(user, id).then(
        (data) => {
          dispatch(success(data, id));
          if (data.role === "uv-rep")
            dispatch(
              alertActions.success(messageConstants.UVREP_UPDATE_SUCCESS)
            );
          else
            dispatch(
              alertActions.success(messageConstants.USER_UPDATE_SUCCESS)
            );

          resolve(data);
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
          reject();
        }
      );
    });
  };

  function request(user) {
    return { type: uvRepConstants.UPDATE_REQUEST, user };
  }
  function success(user) {
    return { type: uvRepConstants.UPDATE_SUCCESS, user };
  }
  function failure(error) {
    return { type: uvRepConstants.UPDATE_FAILURE, error };
  }
}

function _delete(id) {
  return (dispatch) => {
    dispatch(request(id));
    uvRepService._delete(id).then(
      (response) => {
        dispatch(success(id));
        dispatch(alertActions.success(messageConstants.UVREP_UPDATE_FAILURE));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(id) {
    return { type: uvRepConstants.DELETE_REQUEST, id };
  }
  function success(id) {
    return { type: uvRepConstants.DELETE_SUCCESS, id };
  }
  function failure(error) {
    return { type: uvRepConstants.DELETE_FAILURE, error };
  }
}

function attachBank(data) {
  return (dispatch) => {
    dispatch(request(data));
    return new Promise((resolve, reject) => {
      uvRepService.attachBank(data).then(
        (response) => {
          dispatch(success(data));
          dispatch(
            alertActions.success(response.message && response.message[0])
          );
          resolve(response);
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
          reject();
        }
      );
    });
  };

  function request(id) {
    return { type: uvRepConstants.UPDATE_REQUEST, id };
  }
  function success(id) {
    return { type: uvRepConstants.UPDATE_SUCCESS, id };
  }
  function failure(error) {
    return { type: uvRepConstants.UPDATE_FAILURE, error };
  }
}

function transactionListing(data) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      uvRepService.transactionListing(data).then(
        (item) => {
          resolve(item);
        },
        (error) => {
          reject();
        }
      );
    });
  };
}