import { meetAndGreetConstants } from "../constants";
import { meetAndGreetService } from "../services";
import { alertActions } from '.';

export const meetAndGreetAction = {
    list,
    eventDetail,
    OrderList, 
    orderDetail,
    refund,
    refundAll,
    manualPayout
}

function list(params) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            meetAndGreetService.list(params).then(
                (data) => {
                    dispatch(success(data));
                    resolve(data);
                },
                (error) => {
                    dispatch(alertActions.error(error.toString()));
                    reject();
                }
            );
        });
    };

    function success(data) {
        return { type: meetAndGreetConstants.GET_MEET_AND_GREET_LIST_SUCCESS, payload: data };
    }
}
function eventDetail(id) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            meetAndGreetService.detail(id).then(
                (data) => {
                    dispatch(success(data));
                    resolve(data);
                },
                (error) => {
                    dispatch(alertActions.error(error.toString()));
                    reject();
                }
            );
        });
    };

    function success(data) {
        return { type: meetAndGreetConstants.GET_MEET_AND_GREET_EVENT_DETAIL_SUCCESS, payload: data };
    }
}
function OrderList(params) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            meetAndGreetService.getOrderList(params).then(
                (data) => {
                    dispatch(success(data));
                    resolve(data);
                },
                (error) => {
                    dispatch(alertActions.error(error.toString()));
                    reject();
                }
            );
        });
    };

    function success(data) {
        return { type: meetAndGreetConstants.GET_MEET_AND_GREET_ORDER_LIST_SUCCESS, payload: data };
    }
}


function orderDetail(params) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            meetAndGreetService.getOrderDetail(params).then(
                (data) => {
                    dispatch(success(data));
                    resolve(data);
                },
                (error) => {
                    dispatch(alertActions.error(error.toString()));
                    reject();
                }
            );
        });
    };

    function success(data) {
        return { type: meetAndGreetConstants.GET_MEET_AND_GREET_ORDER_DETAIL_SUCCESS, payload: data };
    }
}

function refund(params) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            meetAndGreetService.refund(params).then(
                (response) => {
                    dispatch(success(response?.data));
                    dispatch(alertActions.success(response?.message));
                    resolve(response);
                },
                (error) => {
                    dispatch(alertActions.error(error.toString()));
                    reject();
                }
            );
        });
    };

    function success(data) {
        return { type: meetAndGreetConstants.UPDATE_MEET_AND_GREET_ORDER_SUCCESS, payload: data };
    }
}

function refundAll(params) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            meetAndGreetService.refundAll(params).then(
                (response) => {
                    dispatch(success(response?.data));
                    dispatch(alertActions.success(response?.message));
                    resolve(response);
                },
                (error) => {
                    dispatch(alertActions.error(error.toString()));
                    reject();
                }
            );
        });
    };

    function success(data) {
        return { type: meetAndGreetConstants.UPDATE_MEET_AND_GREET_EVENT_SUCCESS, payload: data };
    }
}

function manualPayout(params) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            meetAndGreetService.manualPayout(params).then(
                (response) => {
                    dispatch(success(response?.data));
                    dispatch(alertActions.success(response?.message));
                    resolve(response);
                },
                (error) => {
                    dispatch(alertActions.error(error.toString()));
                    reject();
                }
            );
        });
    };

    function success(data) {
        return { type: meetAndGreetConstants.UPDATE_MEET_AND_GREET_EVENT_SUCCESS, payload: data };
    }
}