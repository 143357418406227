import { subscriberConstants } from '../constants';

const initialState = { list: [], items: [] };
export function subscriber(state = initialState, action) {
    switch (action.type) {
        case subscriberConstants.EMAIL_LIST_SUCCESS:
            return {
                list: action.payload && action.payload.subscribed,
            };
        case subscriberConstants.SUBSCRIBER_LIST_SUCCESS:
            return {
                list: action.payload && action.payload.items,
            };
        default:
            return state;
    }
}