import React from 'react';
import { Route, Redirect } from 'react-router-dom';
const TheLayout = React.lazy(() => import('./TheLayout'));

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route render={props => localStorage.getItem('token') ? <TheLayout {...props} /> :
        <Redirect to={{ pathname: '/admin/login', state: { from: props.location } }} />
    } />
   /*  <Route {...rest} render={props => (
        localStorage.getItem('token')
            ? <Component {...props} />
            : <Redirect to={{ pathname: '/admin/login', state: { from: props.location } }} />
    )} /> */
)
export default React.memo(PrivateRoute)

