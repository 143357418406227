import { tosConstants } from '../constants';

const initialState = { list: [{ name: ' ', description: 'Loading...', totalProduct: ' ', action: ' ' }], count:0, removeId:null};

export function tos(state = initialState, action) {

    switch (action.type) {
        case tosConstants.GET_TOS_SUCCESS:
            return {
                ...state,
                list: action?.payload?.data ?? [],
                count : action?.payload?.count,
            };
        case tosConstants.UPDATE_TOS_SUCCESS:
            let data = state.list;            
            let index = data.findIndex((r) => r._id === action.payload._id);
            
            if (index > -1) {
                data[index] = action.payload;
                return {...state, list: data };
            } else {
                return state;
            }

        case tosConstants.ADD_TOS_SUCCESS:
            return {
                ...state,
                list: state.list.concat(action.payload),
            };
        case tosConstants.REMOVE_TOS_REQUEST:
            return {
                ...state,
                removeId: action.payload
            }
        case tosConstants.REMOVE_TOS_SUCCESS:
            return {
                ...state,
                list: state.list.filter(function(item) {
                    return item._id !== state.removeId;
                }),
                count : state.count - 1,
                removeId : null
            };
        case tosConstants.REMOVE_TOS_FAILURE:
            return {
                ...state,
                removeId : null
            }
        default:
            return state;
    }

}