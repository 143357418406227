import { homeConstants } from '../constants';
 
const initialState = { list: [], areaList: [], reload_list:true };

export function home(state = initialState,action) {

    switch(action.type) {
        case homeConstants.GET_LIST:
          
          for(let i = 0; i < action.payload.data.length;i++) {
            action.payload.data[i].index = action.payload.data[i]._id;
            action.payload.data[i].id = i;
          }
          return {
              ...state,
              list: action.payload && action.payload.data ? action.payload.data:[],
              reload_list: false
          };

        case homeConstants.GET_AREA_LIST:
            
            let items1 = [];
            for (let i = 0; i < action.payload.length; i++) {
              items1.push({'label': action.payload[i].title , 'value': action.payload[i]._id})
            }

            let items2 = [];
            for (let i = 0; i < state.list.length; i++) {
              items2.push({'label': state.list[i].areaOfConcentration.title , 'value': state.list[i].areaOfConcentration._id})
            }


            function comparer(otherArray){
              return function(current){
                return otherArray.filter(function(other){
                  	return other.value == current.value && other.display == current.display
                }).length == 0;
              }
            }

            var onlyInA = items1.filter(comparer(items2));
            var onlyInB = items2.filter(comparer(items1));

            let result = onlyInA.concat(onlyInB);
            return {
                ...state,
                areaList:result,
                reload_list:false
            };

        case homeConstants.REMOVE_HOME_SUCCESS:
            return {
				...state,
				list:state.list.filter(function(item) {
					return item._id !== action.payload.id
				}),
				reload_list:false
			};
		case homeConstants.GET_HOME_SUCCESS:
			return {
				...state,
				reload_list:true
			}
        default:
            return state;
    
    }

}