import { userConstants } from '../constants';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { loggedIn: true, user,token:false } : {};
//const initialState = { loggedIn: false, token: false };
export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: false,
        user: action.user,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        token: action.user && action.user.token,
        user: action.user
      };
    case userConstants.PROFILE_REQUEST:
      return {
        ...state,
        user: action.user,
      };
    case userConstants.PROFILE_SUCCESS:
      return {
        ...state,
        user: action.user && action.user.data
      };
    case userConstants.LOGOUT:
      return {};
    default:
      return state
  }
}