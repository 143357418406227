import { authHeader } from '../helpers';
export const uvNewsService = {
    index,
    create,
    update
};

function index(request) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = new URL(`${process.env.REACT_APP_API_URL}api/v1/uv-news-feed`)
    url.search = new URLSearchParams({
        offset: request.offset,
        limit: request.limit,
        keyword: request.keyword,
        status: request.status
    })
    return fetch(url, requestOptions).then(handleResponse);
}

function create(user) {
    let formBody = [];
    for (let property in user) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(user[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader() , 'Content-Type': 'application/x-www-form-urlencoded' },
        body: formBody
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/v1/uv-news-feed`, requestOptions)
        .then(handleResponse);
}

function update(request, id) {
    let formBody = [];
    for (let property in request) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(request[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader() , 'Content-Type': 'application/x-www-form-urlencoded' },
        body: formBody
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/v1/uv-news-feed/${id}`, requestOptions)
        .then(handleResponse);
}

function handleResponse(response) {
    return response.json().then(text => {
        const data = text;
        if (!response.ok) {
            /* if (response.status === 401) {
                // auto logout if 401 response returned from api
                //logout();
                //location.reload(true);
            } */

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}