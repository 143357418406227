import { subscriberService } from "../services";
import { alertActions } from "./";
import { subscriberConstants } from "../constants";

export const subscriberAction = {
    guestList,
    exportGuestList,
    subscriberList,
};


function guestList(params) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            subscriberService.guestList(params).then(
                (response) => {
                    dispatch(success(response));
                    resolve(response);
                },
                (error) => {
                    dispatch(alertActions.error(error.toString()));
                    reject();
                }
            );
        });
    };

    function success(payload) {
        return { type: subscriberConstants.EMAIL_LIST_SUCCESS, payload };
    }
};

function subscriberList(params) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            subscriberService.subscriberList(params).then(
                (response) => {
                    dispatch(success(response));
                    resolve(response);
                },
                (error) => {
                    dispatch(alertActions.error(error.toString()));
                    reject();
                }
            );
        });
    };

    function success(payload) {
        return { type: subscriberConstants.SUBSCRIBER_LIST_SUCCESS, payload };
    }
}

function exportGuestList(params) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            subscriberService.exportGuestList(params).then(
                (response) => {
                    resolve(response);
                },
                (error) => {
                    dispatch(alertActions.error(error.toString()));
                    reject();
                }
            );
        });
    };
}