import { userConstants } from "../constants";
import { messageConstants } from "../constants";
import { userService } from "../services";
import history from "../history";
import { alertActions } from "./";

export const userActions = {
  login,
  logout,
  update,
  _delete,
  getProfile,
  retrieveAccountsList,
  addBankDetails,
  updateBankDetails,
  setAsDefaultAccount,
  deleteBankAccount,
  forgotPassword,
};

function login(user) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(request(user));
      userService.login(user).then(
        (user) => {
          dispatch(success(user));
          dispatch(alertActions.success(messageConstants.LOGIN_SUCCESS));
          resolve(user);
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
          reject(error.toString());
        }
      );
    });
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function getProfile() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(request());
      userService.getProfile().then(
        (user) => {
          dispatch(success(user));
          resolve(user);
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
          reject();
        }
      );
    });
  };

  function request() {
    return { type: userConstants.PROFILE_REQUEST };
  }
  function success(user) {
    return { type: userConstants.PROFILE_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.PROFILE_FAILURE, error };
  }
}

function update(user, id, type) {
  
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(request(user));
			userService.update(user, id, type).then((user) => {
				dispatch(success(user, id));
				dispatch(alertActions.success(messageConstants.USER_UPDATE_SUCCESS));
				resolve(user);
			},
			(error) => {
				dispatch(failure(error.toString()));
				dispatch(alertActions.error(error.toString()));
				reject();
			}
			);
		});
	};

	function request(user) {
	return { type: userConstants.UPDATE_REQUEST, user };
	}
	function success(user, id) {
	return { type: userConstants.UPDATE_SUCCESS, user };
	}
	function failure(error) {
	return { type: userConstants.UPDATE_FAILURE, error };
	}
}

function _delete(id, type) {
  return (dispatch) => {
    dispatch(request(id));
    userService._delete(id, type).then(
      (user) => {
        dispatch(success(id));
        dispatch(alertActions.success(messageConstants.USER_DELETE_SUCCESS));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(user) {
    return { type: userConstants.DELETE_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.DELETE_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.DELETE_FAILURE, error };
  }
}

function logout() {
  userService.logout();
  return { type: userConstants.LOGOUT };
}

function retrieveAccountsList(userType='user') {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch({type:userConstants.BANK_ACCOUNT_LIST_REQUEST});
			userService.retrieveAccountsList(userType).then((accounts) => {
					dispatch({type:userConstants.BANK_ACCOUNT_LIST_SUCCESS,payload:accounts});
					resolve(accounts)
			}).catch((error) => {
				dispatch({type:userConstants.BANK_ACCOUNT_LIST_FAILURE,error:error.toString()});
				reject()
			});
		})
	}; 
}

function addBankDetails(data) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(request(data));
            userService.addBankDetails(data).then(
                (user) => {
                    dispatch(success());
					dispatch(alertActions.success(messageConstants.DETAILS_UPDATED));
                    resolve(user)
                },
                (error) => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    reject()
                }
            );
        })
    };

    function request(user) {
        return { type: userConstants.ADD_BANK_REQUEST, user };
    }

    function success(user) {
        return { type: userConstants.ADD_BANK_SUCCESS, user };
    }

    function failure(error) {
        return { type: userConstants.ADD_BANK_FAILURE, error };
    }
}

function updateBankDetails(data, userType) {
    return (dispatch) => {
        
		return new Promise((resolve, reject) => {
            
			dispatch(request(data));
            userService.updateBankDetails(data,userType).then(
                (user) => {

                    dispatch(success());
                    dispatch(alertActions.success(messageConstants.DETAILS_UPDATED));
                    resolve(user)
                
				},
                (error) => {
                    
					          dispatch(failure(error.toString()));
                    dispatch(alertActions.success(error.toString()));
                    reject()
                
				}
            );
        })
    };

    function request(requestParmas) {
        return { type: userConstants.UPDATE_BANK_REQUEST, payload:requestParmas };
    }

    function success(response) {
        return { type: userConstants.UPDATE_BANK_SUCCESS, payload: response };
    }

    function failure(error) {
        return { type: userConstants.UPDATE_BANK_FAILURE, error };
    }
}

function setAsDefaultAccount(accountId) {
	return (dispatch) => {
		
		return new Promise((resolve, reject) => {
			
			dispatch({type:userConstants.BANK_ACCOUNT_SET_DEFAULT_REQUEST});
			userService.setAsDefaultAccount(accountId).then((accounts) => {
					
				dispatch({type:userConstants.BANK_ACCOUNT_SET_DEFAULT_SUCCESS,payload:accounts});
				dispatch(alertActions.success(accounts?.message[0]));
				resolve(accounts)
			
			}).catch((error) => {
				dispatch({type:userConstants.BANK_ACCOUNT_SET_DEFAULT_FAILURE,error:error.toString()});
				dispatch(alertActions.error(error.toString()));
				reject()
			});
		})
	};
}

function deleteBankAccount(accountId) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch({type:userConstants.BANK_ACCOUNT_DELETE_REQUEST});
			userService.deleteBankAccount(accountId).then((accounts) => {
				dispatch({type:userConstants.BANK_ACCOUNT_DELETE_SUCCESS,payload:accounts});
				resolve(accounts)
			}).catch((error) => {
				dispatch({type:userConstants.BANK_ACCOUNT_DELETE_FAILURE,error:error.toString()});
				dispatch(alertActions.error(error.toString()));
				reject()
			});
		})
	};
}

function forgotPassword(data) {
	return (dispatch) => {
		return new Promise((resolve, reject) => { 

			userService.forgotPassword(data).then(() => {
				dispatch(alertActions.success(messageConstants.PASSWORD_SENT_TO_EMAIL));
				resolve()
			}).catch(() => {
				dispatch(alertActions.error(messageConstants.PASSWORD_SENT_TO_EMAIL_ERROR));

				reject()
			})
		});
	}
}
