import { createStore,applyMiddleware } from 'redux'
import rootReducer from './reducers';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { combineReducers } from 'redux';
import { authentication } from './reducers/authentication.reducer';

const loggerMiddleware = createLogger();
  
const initialState = {
  sidebarShow: 'responsive'
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return {...state, ...rest }
    default:
      return state
  }
}

const store = createStore(rootReducer,
				applyMiddleware(thunk, loggerMiddleware));
export default store;