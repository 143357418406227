import { galleryConstants } from '../constants';
 
const initialState = { list: [{name : ' ',performerName: ' ',imageName: 'Loading...',description: 'Loading...',isPremium: ' ', Attachment: ' ',status: ' '}] };

export function gallery(state = initialState,action) {

    switch(action.type) {
        
        case galleryConstants.GET_GALLERY_SUCCESS:
            
            return {
                ...state,
                list:action.payload && action.payload.items?action.payload.items:[],	
            };
        
        case galleryConstants.REMOVE_GALLERY_SUCCESS:
            return {
				...state,
				list:state.list.filter(function(item) {
					return item._id !== action.payload.id
				}),	
			};
        default:
            return state;
    
    }

}