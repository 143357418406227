export const paConstants = {
    GET_PA_SUCCESS: "GET_PA_SUCCESS",
    SHOW_PA_SUCCESS: "SHOW_PA_SUCCESS",
    FILE_TYPE : ['image','video','application'],
    PRESENCE_PROOF_STATUS: {
      PENDING:"pending",
      CONFIRMED:"confirmed",
      NOT_ATTENDED:"not_attended",
    },
    PERSONAL_APPEARANCE_STATUS: [
      "accepted",//0
      "rejected",//1
      "pending",//2
      "complete",//3
      "payment_completed",//4
      "expired",//5
      "celebencer_cancelled",//6
      "merchant_cancelled",//7
      "retry_payment", //8
      "second_installment_transfered", //9
      "admin_cancelled" //10
    ],
};
