import React from 'react';

const FirstLetterCapital = (str) => {
        if (str != undefined) {
        	return str.replace(/(_|^)([^_]?)/g, function(_, prep, letter) {
        		return (prep && ' ') + letter.toUpperCase();
    		});
        } else {
            return str;
        }
    }
export default FirstLetterCapital;