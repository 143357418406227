export const uvRepConstants = {
    ADD_REQUEST:  'ADD_REQUEST_REF',
    ADD_SUCCESS: 'ADD_SUCCESS_REF',
    ADD_FAILURE: 'ADD_FAILURE_REF', 

    UPDATE_REQUEST: 'UPDATE_REQUEST_REF',
    UPDATE_SUCCESS: 'UPDATE_SUCCESS_REF',
    UPDATE_FAILURE: 'UPDATE_FAILURE_REF',
    
    DELETE_REQUEST:  'DELETE_REQUEST_REF',
    DELETE_SUCCESS: 'DELETE_SUCCESS_REF',
    DELETE_FAILURE: 'DELETE_FAILURE_REF',
};