import { combineReducers } from 'redux';
import { authentication } from './authentication.reducer';
import { alert } from './alert.reducer';
import { user } from './user.reducer';
import { commission } from './commission.reducer';
import { setting } from './setting.reducer';
import { category } from './category.reducer';
import { gallery } from './gallery.reducer';
import { uvRep } from './uvRep.reducer';
import { chat } from './chat.reducer';
import { uvNews } from './uvNews.reducer';
import { uvStore } from './uvStore.reducer';
import { home } from './home.reducer';
import { subscriber } from "./subscriber.reducer";
import { faq } from "./faq.reducer";
import { personalAppearnce } from "./personalAppearnce.reducer";
import { requestLimit } from './requestLimit.reducer';
import { industry } from './industry.reducer';
import { tos } from './tos.reducer';
import { meetAndGreet } from './meetAndGreet.reducer';

const initialState = {
    sidebarShow: 'responsive'
}

const changeState = (state = initialState, { type, ...rest }) => {
    switch (type) {
        case 'set':
            return {...state, ...rest }
        default:
            return state
    }
}

const rootReducer = combineReducers({
    changeState,
    authentication,
    alert,
    user,
    commission,
    category,
    gallery,
    setting,
    uvRep,
    chat,
    uvNews,
    uvStore,
    home,
    subscriber,
    faq,
    personalAppearnce,
    requestLimit,
    industry,
    tos,
    meetAndGreet,
});

export default rootReducer;