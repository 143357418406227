import { paConstants } from "../constants";
import { personalAppearnceService } from "../services";
import { alertActions } from '.';

export const personalAppearnceAction = {
    index,
    show,
    refund,
    payout,
}

function index(params) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            personalAppearnceService.index(params).then(
                (data) => {
                    dispatch(success(data));
                    resolve(data);
                },
                (error) => {
                    dispatch(alertActions.error(error.toString()));
                    reject();
                }
            );
        });
    };

    function success(data) {
        return { type: paConstants.GET_PA_SUCCESS, payload: data };
    }
}

function show(id) {
    return personalAppearnceService.show(id);
}

function payout(id,data) {
  return dispatch => {
      return new Promise((resolve, reject) => {
      personalAppearnceService.payout(id,data)
          .then(
              data => {
                  dispatch(success(data));
                  dispatch(alertActions.success(data.message[0]));
                  resolve(data)
              },
              error => {
                  dispatch(alertActions.error(error.toString()));
                  reject()
              }
          );
      });
  };
  function success(data) { return { type: paConstants.GET_PA_SUCCESS, payload:data } }
}

function refund(id,data) {
  return dispatch => {
      return new Promise((resolve, reject) => {
      personalAppearnceService.refund(id,data)
          .then(
              data => {
                  dispatch(success(data));
                  dispatch(alertActions.success(data.message[0]));
                  resolve(data)
              },
              error => {
                  dispatch(alertActions.error(error.toString()));
                  reject()
              }
          );
      });
  };
  function success(data) { return { type: paConstants.GET_PA_SUCCESS, payload:data } }
}
