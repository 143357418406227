import { authHeader } from "../helpers";

export const emailService = {
  getList,
  getById,
  update,
};

function getList(params) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let url = new URL(
    `${process.env.REACT_APP_API_URL}api/v1/Template`
  );
  url.search = new URLSearchParams(params)
  return fetch(url, requestOptions).then(handleResponse);
}

function handleResponse(response) {
  return response.json().then((text) => {
    const data = text;

    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}

function getById(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}api/v1/Template/${id}`,
    requestOptions
  ).then(handleResponse);
}

function update(body, id) {
  let formBody = [];
  for (let property in body) {
    let encodedKey = encodeURIComponent(property);
    let encodedValue = encodeURIComponent(body[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");

  const requestOptions = {
    method: "PUT",
    headers: {
      ...authHeader(),
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: formBody,
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}api/v1/Template/${id}`,
    requestOptions
  ).then(handleResponse);
}

