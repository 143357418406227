import { requestLimitConstant } from "src/constants/requestLimit.constants";
const initialState = { limitDetails: {}, submitting: false }

export function requestLimit(state = initialState, action) {
    switch (action.type) {
        case requestLimitConstant.REQUEST:
            return {
                ...state,
                submitting: true,
            };
            break;

        case requestLimitConstant.DETAILS:
            return {
                ...state,
                limitDetails: action.payload,
                submitting: false,
            };
            break;

        case requestLimitConstant.UPDATE:
            return {
                ...state,
                limitDetails: action.payload,
                submitting: false,
            };
            break;
        case requestLimitConstant.FAILED:
            return {
                ...state,
                submitting: false,
            };
            break;

        default:
            return state;
            break;
    }

}