import { industryConstants } from '../constants';

const initialState = { industryList: [{title: 'Loading...',action: ' '}] };
export function industry(state = initialState,action) {    
    switch(action.type) {
        case industryConstants.GET_INDUSTRIES_SUCCESS: 
            return {
                ...state,
                industryList:action.payload && action.payload.industries?action.payload.industries:[],	
            };
        case industryConstants.UPDATE_INDUSTRY_SUCCESS:
            let data = state.industryList;
            let index = data.findIndex(r => r._id === action.payload._id) 
            if(index > -1) {
              data[index] = action.payload
              return { ...state, industryList: data }
            } else { 
                return state
            }
        case industryConstants.ADD_INDUSTRY_SUCCESS:
            return {
                ...state,
                industryList:state.industryList.concat(action.payload),	
            };
        case industryConstants.REMOVE_INDUSTRY_SUCCESS:
            return {
				...state,
				industryList: state.industryList.filter(function(item) {
					return item._id !== action.payload.id
				}),	
			};
        default:
            return state;
    
    }

}