import { uvStoreConstants } from '../constants';

const initialState = {};
export function uvStore(state = initialState, action) {
  switch (action.type) {
    case uvStoreConstants.GET_STORE_SUCCESS:
    const data = action.result && action.result.data && action.result.data.products.map(function(item, key) {
      item['performerName'] = item.performerId != null ? 
      item.performerId && item.performerId.firstName ? item.performerId.firstName + ' ' + item.performerId.lastName : item.performerId.name : 
        'N/A'
      return item;
    })
      return {
        list: data,
      };
    case uvStoreConstants.ADD_SUCCESS:
      return {
        loggedIn: true,
        token: action.user && action.user.token,
        user: action.user
      };
    case uvStoreConstants.ADD_FAILURE:
      return {};
    case uvStoreConstants.DELETE_REQUEST:
      return {
        submitted: true,
      };
    case uvStoreConstants.DELETE_SUCCESS:
      return {
        deleted: true
      };
    case uvStoreConstants.DELETE_FAILURE:
      return {};
    case uvStoreConstants.CHANGE_ADMIN_STATUS_REQUEST:
      return {
        user: action.user,
      };
    case uvStoreConstants.CHANGE_ADMIN_STATUS_SUCCESS:
      return {
        token: action.user && action.user.token,
        user: action.user
      };
    case uvStoreConstants.CHANGE_ADMIN_STATUS_FAILURE:
      return {};
	case  uvStoreConstants.GET_MARKETPLACE_LIST_SUCCESS:
		return {
			marketPlaceList : action.result.data,
			marketPlaceCount : action.result.count
		}
    default:
      return state
  }
}