import React, { useEffect, useState, useContext } from "react";
import {
  CBadge,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImg,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { chatActions } from "../actions";

import { TimeAgo } from "../reusable";
import { imageWithPath } from "src/helpers";
import { WebSocketContext } from "../WebSocket";

const TheHeaderDropdownMssg = () => {
  const dispatch = useDispatch();
  const itemsCount = 4;
  const history = useHistory();
  const [notification, setnotification] = useState(false);
  const [notificationCount, setnotificationCount] = useState("");
  const isActiveUser = useSelector(
    (state) => state.chat && state.chat.isActiveUser
  );
  const ws = useContext(WebSocketContext);
  
  const getNotification = () => {
    if (notification.length <= 0) {
      history.push("/admin/message");
    } else {
      dispatch(chatActions.getUnreadMsg()).then((data) => {
        let filterData =
          data &&
          data.messages.filter((item) => item.conversationId != isActiveUser);
        setnotification(filterData);
        setnotificationCount(filterData.length);
      });
    }
  };

  useEffect(() => {
    dispatch(chatActions.getUnreadMsg()).then((data) => {
      setnotification(data && data.messages);
      setnotificationCount(data && data.count);
    });
    ws.socket.emit("JOIN_ROOM", "RELOAD_CHAT");
    ws.socket.on("RELOAD_CHAT", function (data) {
      if (data.reload) {
        dispatch(chatActions.getUnreadMsg()).then((data) => {
          let latestData =
            data &&
            data.messages.filter((item) => item.conversationId != isActiveUser);
          setnotification(latestData);
          setnotificationCount(latestData.length);
        });
      }
    });
  }, [isActiveUser]);
  return (
    <CDropdown inNav className="c-header-nav-item mx-2" direction="down">
      <CDropdownToggle
        className="c-header-nav-link"
        caret={false}
        onClick={() => getNotification()}
      >
        <CIcon name="cil-envelope-open" />
        <CBadge shape="pill" color="info">
          {notificationCount > 0 ? notificationCount : ""}
        </CBadge>
      </CDropdownToggle>
      {notificationCount > 0 ? (
        <CDropdownMenu className="pt-0" placement="bottom-end">
          <CDropdownItem header tag="div" color="light">
            {notificationCount > 0 ? (
              <strong>You have {notificationCount} messages</strong>
            ) : (
              <strong>You have no new messages</strong>
            )}
          </CDropdownItem>
          <div className="header-notify-scroll">
            {notification &&
              notification.map((item, index) => (
                <CDropdownItem href="#" key={index}>
                  <div className="user-list p-0 message">
                    <span className="c-avatar contact-image sender-img mb-0">
                      <CImg
                        src={
                          item?.senderId?.imageThumbPath &&
                          imageWithPath(
                            item.senderId.imageThumbPath,
                            null,
                            item?.senderId?.imageType
                          )
                        }
                        className=""
                        alt={item.senderId && item.senderId.email}
                        width="25"
                        height="25"
                      />
                      {/* <span className="c-avatar-status bg-success"></span> */}
                    </span>
                    <div className="contact-detail">
                      <div className="mt-n2 w-75">
                        <small className="text-muted mt-n1">
                          {item.senderId && item.senderId.name}
                        </small>
                        <div className="text-truncate font-weight-bold notification-text">
                          <span className="fa fa-exclamation text-danger"></span>
                          {item.content}
                        </div>
                      </div>
                      <div className="w-25 text-muted text-right">
                        <small className="">
                          {TimeAgo(new Date(item.updatedAt).getTime())}
                        </small>
                      </div>
                    </div>
                    {/* <div className="small text-muted text-truncate">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt...
                </div> */}
                  </div>
                </CDropdownItem>
              ))}
          </div>
          <CDropdownItem
            href="#"
            onClick={() => history.push("/admin/message")}
            className="text-center border-top"
          >
            <strong>View all messages</strong>
          </CDropdownItem>
        </CDropdownMenu>
      ) : null}
    </CDropdown>
  );
};

export default TheHeaderDropdownMssg;
